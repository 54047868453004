<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <ng-container *ngIf="DashboardValues | async as item">
            <div class="col-xl-3 col-md-6 xl-50 mb-3">
                <div class="card o-hidden widget-cards h-100 " style="cursor: pointer;"
                    [routerLink]="['/products/digital/digital-add-product/add']">
                    <div class="bg-warning card-body" title="Add New Product">
                        <div class="media static-top-widget row">
                            <div class="icons-widgets col-4">
                                <div class="align-self-center text-center">
                                    <app-feather-icons [icon]="'navigation'" class="font-warning"></app-feather-icons>
                                </div>
                            </div>
                            <div class="media-body col-8"><span class="m-0 text-light">{{'Dashboard.NewProduct' |
                                    translate}}</span>
                                <h3 class="mb-0"><span class="counter">{{ item.ProductsNew }}</span><small>
                                        {{'Dashboard.LastOneMonth' | translate}}</small>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 xl-50 mb-3">
                <div class="card o-hidden  widget-cards h-100 " style="cursor: pointer;"
                    [routerLink]="['/products/digital/digital-product-list']">
                    <div class="bg-secondary card-body" title="All active Products">
                        <div class="media static-top-widget row">
                            <div class="icons-widgets col-4">
                                <div class="align-self-center text-center">
                                    <app-feather-icons [icon]="'box'" class="font-secondary"></app-feather-icons>
                                </div>
                            </div>
                            <div class="media-body col-8"><span class="m-0 text-light">{{'Sidebar.ActiveProducts' |
                                    translate}}</span>
                                <h3 class="mb-0"><span class="counter">
                                        {{item.ProductsTotal }}</span>
                                        <!-- <small>{{'Products.TotalActiveProducts'| translate}}</small> -->
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 xl-50 mb-3" *ngIf="user.ID_perfil=='1'">
                <div class="card o-hidden widget-cards h-100 " style="cursor: pointer;"
                    [routerLink]="['/users/users-loggins']">
                    <div class="bg-primary card-body" title="User Logging's list">
                        <div class="media static-top-widget row">
                            <div class="icons-widgets col-4">
                                <div class="align-self-center text-center">
                                    <app-feather-icons [icon]="'message-square'" class="font-primary">
                                    </app-feather-icons>
                                </div>
                            </div>
                            <div class="media-body col-8"><span class="m-0 text-light">{{'Dashboard.Login' |
                                    translate}}</span>
                                <h3 class="mb-0"><span class="counter">{{item.LoginCount}}</span> <small>
                                        {{'Dashboard.LastWeek' | translate}}</small></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 xl-50 mb-3" *ngIf="user.ID_perfil=='1' || user.ID_perfil=='2'">
                <div class="card o-hidden widget-cards h-100 " style="cursor: pointer;"
                    [routerLink]="['/customers/list-customer']">
                    <div class="bg-info card-body" title="All active clients list">
                        <div class="media static-top-widget row">
                            <div class="icons-widgets col-4">
                                <div class="align-self-center text-center">
                                    <app-feather-icons [icon]="'users'" class="font-danger"></app-feather-icons>
                                </div>
                            </div>
                            <div class="media-body col-8"><span class="m-0 text-light"> {{'Dashboard.ActiveClients' |
                                    translate}}</span>
                                <h3 class="mb-0"><span class="counter">{{item.ActiveClients }}</span><small>
                                        {{'Dashboard.LastMonth' | translate}}</small></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 xl-50 mb-3">
                <div class="card o-hidden widget-cards h-100 " style="cursor: pointer;" [routerLink]="['/orders/list-orders']">
                    <div class="bg-danger card-body" title="List of orders">
                        <div class="media static-top-widget row">
                            <div class="icons-widgets col-4">
                                <div class="align-self-center text-center">
                                    <app-feather-icons [icon]="'users'" class="font-danger"></app-feather-icons>
                                </div>
                            </div>
                            <div class="media-body col-8"><span class="m-0 text-light">{{'Dashboard.Orders' |
                                    translate}}</span>
                                <h3 class="mb-0"><span class="counter">{{item.OrdersLastMonth}}</span><small>
                                        {{'Dashboard.LastOneMonth' | translate}}</small></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 xl-50 mb-3" style="cursor: pointer;" (click)="changesEnumValue('One')">
                <div class="card o-hidden widget-cards h-100 ">
                    <div class="bg-success card-body" title="Top 10 Products">

                        <div class="media static-top-widget row">
                            <div class="icons-widgets col-4">
                                <div class="align-self-center text-center">
                                    <app-feather-icons [icon]="'users'" class="font-danger"></app-feather-icons>
                                </div>
                            </div>
                            <div class="media-body col-8"><span class="m-0 text-light">{{'Dashboard.TopProduct' |
                                    translate}}</span>
                                <p class="mb-0 text-light">{{'Dashboard.LastOneMonth' | translate}}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-md-6 xl-50 mb-3" style="cursor: pointer;" (click)="changesEnumValue('Two')">
                <div class="card o-hidden widget-cards h-100 ">
                    <div class="bg-danger card-bodybg-dark card-body" title="Latest created products">

                        <div class="media static-top-widget row">
                            <div class="icons-widgets col-4">
                                <div class="align-self-center text-center">
                                    <app-feather-icons [icon]="'users'" class="font-danger"></app-feather-icons>
                                </div>
                            </div>
                            <div class="media-body col-8"><span class="m-0 text-light">{{'Dashboard.LatestProduct' |
                                    translate}}</span>
                                <p class="mb-0 text-light">{{'Dashboard.LastOneMonth' | translate}}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-md-6 xl-50 mb-3" style="cursor: pointer;" (click)="changesEnumValue('Three')">
                <div class="card o-hidden widget-cards h-100 ">
                    <div class="bg-dark card-body" title="Top 10 suggested products">

                        <div class="media static-top-widget row">
                            <div class="icons-widgets col-4">
                                <div class="align-self-center text-center">
                                    <app-feather-icons [icon]="'users'" class="font-danger"></app-feather-icons>
                                </div>
                            </div>
                            <div class="media-body col-8">
                                <span class="m-0 text-light">{{'Dashboard.Top10SuggestedProducts' | translate}}</span>
                                <!-- <p class="mb-0">{{'Dashboard.SuggestedProducts' | translate}}</p> -->
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </ng-container>
        <!-- <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Market Value</h5>
                </div>
                <div class="card-body">
                    <div class="market-chart">
                        <x-chartist class="board-chart ct-golden-section" [data]="chart3.data" [type]="chart3.type"
                            [options]="chart3.options" [events]="chart3.events">
                        </x-chartist>
                    </div>
                </div>
            </div>
        </div> -->
        <span *ngIf="IsProductShow">
            <div class="col-xl-12 xl-100" *ngIf="TopProductList | async as item">
                <div class="card height-equal">
                    <div class="card-header">
                        <h5>{{'Dashboard.Top10Product' | translate}}</h5>
                    </div>
                    <div class="card-body">
                        <div class="user-status table-responsive latest-order-table">
                            <table class="table table-bordernone">
                                <thead>
                                    <tr>
                                        <th scope="col">{{'Dashboard.ProductID' | translate}}</th>
                                        <th scope="col">{{'Dashboard.ProductTitle' | translate}}</th>
                                        <th scope="col">{{'Dashboard.ProductCode' | translate}}</th>
                                        <th scope="col">{{'Dashboard.Date' | translate}}</th>
                                        <th scope="col">{{'Dashboard.Action' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let obj of item">
                                    <tr>
                                        <td>{{obj.iD_Produto}}</td>
                                        <td>{{obj.C_Produto ? obj.C_Produto : '--'}}</td>
                                        <td>{{obj.codigo_Produto}}</td>
                                        <td>{{obj.dtIntro | date:'yyyy-MM-dd'}}</td>
                                        <td>&nbsp;&nbsp; 
                                            <a href="javascript:void(0)" 
                                                (click)="GoToDetail(obj.iD_Produto)">
                                                <i class="fa fa-info-circle showcursor"></i>
                                            </a>&nbsp;&nbsp; 
                                            <a href="javascript:void(0)" title="Product related to orders"
                                                [routerLink]="['/products/digital/product&Orders', obj.iD_Produto]">
                                                <i class="fa fa-regular fa-box"></i>
                                            </a>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                    <td>2</td>
                                    <td class="digits">$90.00</td>
                                    <td class="font-secondary">Ewallets</td>
                                    <td class="digits">Delivered</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td class="digits">$240.00</td>
                                    <td class="font-warning">Cash</td>
                                    <td class="digits">Delivered</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td class="digits">$120.00</td>
                                    <td class="font-primary">Direct Deposit</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td class="digits">$50.00</td>
                                    <td class="font-primary">Bank Transfers</td>
                                    <td class="digits">Delivered</td>
                                </tr> -->
                                </tbody>
                            </table>
                            <a href="/products/digital/digital-product-list" class="btn btn-primary">{{'Dashboard.ViewAllProducts' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </span>

        <span *ngIf="isTopProductShow">
            <div class="col-xl-12 xl-100" *ngIf="LatestProductList | async as item">
                <div class="card height-equal">
                    <div class="card-header">
                        <h5>{{'Dashboard.Latest10Product' | translate}}</h5>
                    </div>
                    <div class="card-body">
                        <div class="user-status table-responsive latest-order-table">
                            <table class="table table-bordernone">
                                <thead>
                                    <tr>
                                        <th scope="col">Product ID</th>
                                        <th scope="col">Product Title</th>
                                        <th scope="col">Product Code</th>
                                        <th scope="col">Date</th>
                                        <!-- <th scope="col">Material</th> -->
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let obj of item">
                                    <tr>
                                        <td>{{obj.iD_Produto}}</td>
                                        <td>{{obj.c_Produto ? obj.c_Produto : '--'}}</td>
                                        <td>{{obj.codigo_Produto}}</td>
                                        <td class="w-40">{{obj.dtIntro | date:'yyyy-MM-dd'}}</td>
                                        <!-- <td>{{obj.n_Material}}</td> -->
                                        <td>&nbsp;&nbsp; <a href="javascript:void(0)" (click)="GoToDetail(obj.iD_Produto
                                        )"><i class="fa fa-info-circle showcursor"></i></a></td>
                                    </tr>

                                </tbody>
                            </table>
                            <a href="/products/digital/digital-product-list" class="btn btn-primary">View All
                                Products</a>
                        </div>
                    </div>
                </div>
            </div>
        </span>

        <span *ngIf="IsSuggestingProduct">
            <!-- <div class="col-xl-8 xl-100">
                <div class="card height-equal">
                    <div class="card-header">
                        <h5>Select Clients</h5><br>
                        <label class="col-form-label"> {{'Common.Providers' | translate}}</label>
                        <ng-select class="custom placeholder-size col-6" placeholder="Select Client" [items]="Clients"
                            bindValue="iD_Entidade" bindLabel="nM_Cliente" (change)="getSuggestedProductList($event)"
                            [(ngModel)]="SelectedClients">
                        </ng-select>
                    </div>
                </div>
            </div> -->

            <div class="col-xl-12 xl-100" *ngIf="SuggestedProductS">
                <div class="card height-equal">
                    <div class="card-header">
                        <h5>{{'Dashboard.Top10SuggestedProducts' | translate}}</h5>
                    </div>
                    <div class="card-body">
                        <div class="user-status table-responsive latest-order-table">
                            <table class="table table-bordernone">
                                <thead>
                                    <tr>
                                        <th scope="col">Product ID</th>
                                        <th scope="col">Product Title</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Product Code</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let obj of SuggestedProductS">
                                    <tr>
                                        <td>{{obj.ID_Produto}}</td>
                                        <td>{{obj.C_Produto ? obj.C_Produto : '--'}}</td>
                                        <td>{{obj.dtIntro | date:'yyyy-MM-dd'}}</td>
                                        <td>{{obj.Codigo_Produto}}</td>
                                        <td>&nbsp;&nbsp; <a href="javascript:void(0)"
                                                (click)="GoToDetail(obj.ID_Produto)">
                                                <i class="fa fa-info-circle showcursor"></i>
                                            </a>&nbsp;&nbsp;
                                            <a [routerLink]="['/products/digital/product&client/', obj.ID_Produto]" title="Product suggested to clients">
                                                <i class="fa fa-regular fa-box"></i>
                                            </a>
                                        </td>
                                    </tr>

                                </tbody>

                            </table>
                            <div>
                                <p class="text-center text-danger " *ngIf="SuggestedProductS.length == 0">
                                    No Records Found
                                </p>
                            </div>
                            <!-- <a href="/products/digital/digital-product-list" class="btn btn-primary">View All
                                Products</a> -->
                            <!-- <button class="btn btn-primary" style="margin-left: 5px;" (click)="DeleteFromSuggestions()">Remove Product Suggestion</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </span>

        <!-- <div class="col-xl-3 col-md-6 xl-50">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h6>Total Sales</h6>
                    <div class="row">
                        <div class="col-6">
                            <div class="small-chartjs">
                                <canvas baseChart id="small-graph-1" height="600" width="400"
                                    [datasets]="smallLineChartData" [labels]="smallLineChartLabels"
                                    [options]="smallLineChartOptions" [legend]="smallLineChartLegend"
                                    [type]="smallLineChartType" (chartHover)="chartHovered($event)"
                                    (chartClick)="chartClicked($event)"></canvas>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="value-graph">
                                <h3>42% <span><i class="fa fa-angle-up font-primary"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-start">
                        <div class="media-body">
                            <span>Sales Last Month</span>
                            <h2 class="mb-0">9054</h2>
                            <p>0.25% <span><i class="fa fa-angle-up"></i></span></p>
                            <h5 class="f-w-600">Gross sales of August</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                        </div>
                        <div class="bg-primary b-r-8">
                            <div class="small-box">
                                <i data-feather="briefcase"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h6>Total purchase</h6>
                    <div class="row">
                        <div class="col-6">
                            <div class="small-chartjs">
                                <canvas baseChart id="small-graph-2" height="60vw" width="140vw"
                                    [datasets]="smallLine2ChartData" [labels]="smallLine2ChartLabels"
                                    [options]="smallLine2ChartOptions" [legend]="smallLine2ChartLegend"
                                    [type]="smallLine2ChartType" (chartHover)="chartHovered($event)"
                                    (chartClick)="chartClicked($event)"></canvas>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="value-graph">
                                <h3>20% <span><i class="fa fa-angle-up font-secondary"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-start">
                        <div class="media-body">
                            <span>Monthly purchase</span>
                            <h2 class="mb-0">2154</h2>
                            <p>0.13% <span><i class="fa fa-angle-up"></i></span></p>
                            <h5 class="f-w-600">Avg Gross purchase</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                        </div>
                        <div class="bg-secondary b-r-8">
                            <div class="small-box">
                                <i data-feather="credit-card"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h6>Total cash transaction</h6>
                    <div class="row">
                        <div class="col-6">
                            <div class="small-chartjs">
                                <canvas baseChart id="small-graph-3" height="60vw" width="140vw"
                                    [datasets]="smallLine3ChartData" [labels]="smallLine3ChartLabels"
                                    [options]="smallLine3ChartOptions" [legend]="smallLine3ChartLegend"
                                    [type]="smallLine3ChartType" (chartHover)="chartHovered($event)"
                                    (chartClick)="chartClicked($event)"></canvas>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="value-graph">
                                <h3>28% <span><i class="fa fa-angle-up font-warning"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-start">
                        <div class="media-body">
                            <span>Cash on hand</span>
                            <h2 class="mb-0">4672</h2>
                            <p>0.8% <span><i class="fa fa-angle-up"></i></span></p>
                            <h5 class="f-w-600">Details about cash</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                        </div>
                        <div class="bg-warning b-r-8">
                            <div class="small-box">
                                <i data-feather="shopping-cart"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h6>Daily Deposits</h6>
                    <div class="row">
                        <div class="col-6">
                            <div class="small-chartjs">
                                <canvas baseChart id="small-graph-4" height="60vw" width="140vw"
                                    [datasets]="smallLine4ChartData" [labels]="smallLine4ChartLabels"
                                    [options]="smallLine4ChartOptions" [legend]="smallLine4ChartLegend"
                                    [type]="smallLine4ChartType" (chartHover)="chartHovered($event)"
                                    (chartClick)="chartClicked($event)"></canvas>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="value-graph">
                                <h3>75% <span><i class="fa fa-angle-up font-danger"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-start">
                        <div class="media-body">
                            <span>Security Deposits</span>
                            <h2 class="mb-0">0782</h2>
                            <p>0.25% <span><i class="fa fa-angle-up"></i></span></p>
                            <h5 class="f-w-600">Gross sales of June</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                        </div>
                        <div class="bg-danger b-r-8">
                            <div class="small-box">
                                <i data-feather="calendar"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Buy / Sell</h5>
                </div>
                <div class="card-body sell-graph">
                    <canvas baseChart id="myGraph" [datasets]="lineChartData" [labels]="lineChartLabels"
                        [options]="lineChartOptions" [legend]="lineChartLegend" [type]="lineChartType"
                        (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div>
        <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Products Cart</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive products-table">
                        <table class="table table-bordernone mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Details</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Simply dummy text of the printing</td>
                                    <td class="digits">1</td>
                                    <td class="font-primary">Pending</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>Long established</td>
                                    <td class="digits">5</td>
                                    <td class="font-secondary">Cancle</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>sometimes by accident</td>
                                    <td class="digits">10</td>
                                    <td class="font-secondary">Cancle</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>Classical Latin literature</td>
                                    <td class="digits">9</td>
                                    <td class="font-primary">Return</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>keep the site on the Internet</td>
                                    <td class="digits">8</td>
                                    <td class="font-primary">Pending</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>Molestiae consequatur</td>
                                    <td class="digits">3</td>
                                    <td class="font-secondary">Cancle</td>
                                    <td class="digits">$6523</td>
                                </tr>
                                <tr>
                                    <td>Pain can procure</td>
                                    <td class="digits">8</td>
                                    <td class="font-primary">Return</td>
                                    <td class="digits">$6523</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Empolyee Status</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive products-table">
                        <table class="table table-bordernone mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Designation</th>
                                    <th scope="col">Skill Level</th>
                                    <th scope="col">Experience</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/user2.jpg" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>John Deo <span class="text-muted digits">(14+ Online)</span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Designer</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-primary" role="progressbar"
                                                    style="width: 30%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">2 Year</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/user1.jpg" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>Holio Mako <span class="text-muted digits">(250+ Online)</span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Developer</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-secondary" role="progressbar"
                                                    style="width: 70%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">3 Year</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/man.png" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>Mohsib lara<span class="text-muted digits">(99+ Online)</span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Tester</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-primary" role="progressbar"
                                                    style="width: 60%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">5 Month</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/user.png" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>Hileri Soli <span class="text-muted digits">(150+ Online)</span>
                                                </h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Designer</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-secondary" role="progressbar"
                                                    style="width: 30%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">3 Month</td>
                                </tr>
                                <tr>
                                    <td class="bd-t-none u-s-tb">
                                        <div class="align-middle image-sm-size"><img
                                                class="img-radius align-top m-r-15 rounded-circle"
                                                src="assets/images/dashboard/designer.jpg" alt="" data-original-title=""
                                                title="">
                                            <div class="d-inline-block">
                                                <h6>Pusiz bia <span class="text-muted digits">(14+ Online)</span></h6>
                                            </div>
                                        </div>
                                    </td>
                                    <td>Designer</td>
                                    <td>
                                        <div class="progress-showcase">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar bg-primary" role="progressbar"
                                                    style="width: 90%" aria-valuenow="50" aria-valuemin="0"
                                                    aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="digits">5 Year</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Sales Status</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph overflow-hidden">
                                <h6>Orders By Location</h6>
                                <div class="chart-block chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="pieData" [explodeSlices]="false"
                                        [labels]="doughnutChartShowLabels" [arcWidth]=0.50 [doughnut]="true"
                                        [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="order-graph-bottom">
                                    <div class="media">
                                        <div class="order-color-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Saint Lucia <span class="pull-right">$157</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-secondary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Kenya <span class="pull-right">$347</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-danger"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Liberia<span class="pull-right">$468</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-warning"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Christmas Island<span class="pull-right">$742</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-success"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Saint Helena <span class="pull-right">$647</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space overflow-hidden">
                                <h6>Sales By Location</h6>
                                <div class="peity-chart-dashboard text-center chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="doughnutData"
                                        [explodeSlices]="false" [labels]="doughnutChartShowLabels" [arcWidth]=0.99
                                        [doughnut]="true" [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="order-graph-bottom sales-location">
                                    <div class="media">
                                        <div class="order-shape-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Germany <span class="pull-right">25%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-secondary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Brasil <span class="pull-right">10%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-danger"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">United Kingdom<span class="pull-right">34%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-warning"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Australia<span class="pull-right">5%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-success"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Canada <span class="pull-right">25%</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 xl-100">
                            <div class="order-graph xl-space">
                                <h6>Revenue for last month</h6>
                                <div class="ct-4 flot-chart-container">
                                    <x-chartist [type]="chart5.type" [data]="chart5.data" [options]="chart5.options">
                                    </x-chartist>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</div>
<!-- Container-fluid Ends-->