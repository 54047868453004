import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from '../../service/nav.service';
import { UserService } from '../../service/user.service';
import { GenericService } from '../../service/generic.service';
import { CurrenciesService } from '../../service/currencies.service';
import { DigitalListComponent } from 'src/app/components/products/digital/digital-list/digital-list.component';
import { ViewChild } from '@angular/core';
import { SharedClientServiceService } from 'src/app/shared-client-service.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  selectedClientName: string = '';
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile: boolean;
  public Currencies: any;
  public lang: string;

  selectedCurrency: any;
  selectedCurrencySymb : any;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(public navServices: NavService,
    private userService: UserService,
    private router: Router,
    private genericService: GenericService,
    private currencyService: CurrenciesService,
     private sharedClientService: SharedClientServiceService,
  ) { }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }


  ngOnInit() {
    this.lang = localStorage.getItem("Language");
    this.selectedCurrencySymb = localStorage.getItem("Currency");
    this.GetAllActiveCurrencies();

    this.sharedClientService.currentClient$.subscribe(clientName => {
      this.selectedClientName = clientName;
      // console.log('Selected client in Header:', this.selectedClientName);
    });
  
  }

  GetAllActiveCurrencies(){
    
    this.currencyService.getAllActiveCurrency().subscribe((res: any) => {
      this.Currencies = res.Data;
      // console.log(this.Currencies.find(x => x.status == 1));
      // console.log(this.Currencies);
      this.selectedCurrency = '$';
    });
  }


  logout() {
    console.log("logout");
    this.userService.logout();
    this.router.navigate(['/auth/login']);
  }


  switchLanguage(lang: any) {
    this.lang = lang;
    localStorage.setItem("Language", lang);
    window.location.reload();
  }

  switchCurrency(Currency: any) {
    
 
    this.selectedCurrencySymb = Currency;
    localStorage.setItem("Currency", Currency);
    window.location.reload();
  }
}
