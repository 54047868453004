import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HelperService } from 'src/app/shared/service/helper.service';
import { ProductService } from 'src/app/shared/service/product.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifyService } from 'src/app/shared/service/notify.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductPersonalizeService } from 'src/app/shared/service/product-personalize.service';
import { NgbModalRef, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-porduct-personalization',
  templateUrl: './porduct-personalization.component.html',
  styleUrls: ['./porduct-personalization.component.scss']
})
export class PorductPersonalizationComponent implements OnInit {
  @ViewChild('productModal') productModal: TemplateRef<any>;
  currentPreferredIndex: number | null = null;
  page: any;
  pageSize: any;
  productId: any;
  productCode: string = ''
  total: number = 0;
  productDetials: any;
  showAndHide: boolean = false;
  currentIndexPersonalized: number = 0;
  selectedProduct: any;
  selectedClient: any | string;
  NewPersonalizeForm: FormGroup | any;
  personalizeProductDetials: any;
  productDetialsImage: any;
  personalizedProductImage: any[]=[];

  clientList: any[] = [];
  ProductsList: any[] = []; //product list in dropdown

  cardImageIndex: any[] = [];
  productImageIndex: any[] = [];
  DefaultImage:any[]=[];
  cardImageFiles: File[] = []; // array of images for cards
  productImageFiles: File[] = []; // array of images for products
  personalizeHistoryList: any[] = [];
  personalizedImagesList: any[] = [];
  productOptions: any[] = [];
 ProductImage: any[]=[]

  productImagePath: any[] = [];
  cardImagePath: any[] = [];


  product: any;
  orderId: any = null;
  personalizedImages: any[] = [];
  ProductImageFiles: any[] = [];
  Images: any[] = [];
  videos: any[] = [];
  extension: any;
  defaultImagePath: any;
  defaultImageextension: any;
  currentIndex: number = 0;
  // ProductImageFiles: any[] = [];
  selectedImages: any[] = [];


  validationMapping = {
    Code_Barras: { required: 'Bard code is required for Personalize' },
    // cardImage: { required: 'Card Images are required' },
  }

  pageConfig: any = {
    curPage: 1,
    perPage: 10,
    sortBy: 'Id',
    direction: 'desc',
    whereClauses: [],
  };

  filters = {
    product: { Value: null, Operator: '=' },
    iD_Entidade: { Value: '0', Operator: '=' },
    IsActiveProducts: { Value: 'true', Operator: '=' }
  }
  PrdocutdefaultImgs: any[] = [];

  constructor(
    public helperService: HelperService,
    private notify: NotifyService,
    public modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private activeRoute: ActivatedRoute,
    private productService: ProductService,
    private personlizeService: ProductPersonalizeService,
    Rconfig: NgbRatingConfig,
    config: NgbCarouselConfig,
  ) { }


  ngOnInit(): void {
    // this.activeRoute.params.subscribe((params) => {
    //   if (params['id']) {
    //     this.productId = params['id'];
    //     console.log('onint:', this.productId);
    //   }
    // });

    this.GetProductByID();
    this.GetAllClients();
    this.onFormInit();
    this.onProductChange();
    // this.getProductdetails();

  }

  onFormInit() {
    this.NewPersonalizeForm = new FormGroup({
      Code_Barras: new FormControl(null),
      Provider_Client_Id_Entidade: new FormControl(null),
      Id_Produto: new FormControl(parseInt(this.productId)),
      Id: new FormControl(0),
      HistoryPersonalizeName: new FormControl(null),
      N_ValorUnitario: new FormControl(null),
      N_ValorFornecedor: new FormControl(null),
      N_ValorApresentacao: new FormControl(null),
      PresentationEN: new FormControl(null),
      // isPrefered: new FormControl(false),
    });
  }

  GetProductByID() {

    if (this.productId) {

      this.productService.GetProductById(this.productId).subscribe((res: any) => {
        this.spinner.show();
        if (res.ResponseCode == 200) {
          this.spinner.hide();
          this.productDetials = res.Data;
          this.selectedProduct = res.Data.ID_Produto;
          this.PrdocutdefaultImgs = res.Data.prodfiles;
        
        }
        else {
          this.spinner.hide();
          this.notify.showWarning('Warning', 'Product Id not found');
        }
      }, (err) => {
        this.spinner.hide();
        return err;
      });
    }
  }


  GetAllClients() {
    this.productService.GetAllClientsByRegister().subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.clientList = res.Data;
      } else {
        this.clientList = [];
      }
    });
  }

  onChangeofSelect(clientID: number) {
    if (clientID) {
      this.selectedClient = clientID;
    }
  }


  // addNewPersonalization() {

  //   this.NewPersonalizeForm.get('Provider_Client_Id_Entidade').setValue(this.selectedClient);
  //   this.NewPersonalizeForm.get('Id_Produto').setValue(this.selectedProduct);
  //   const formData = new FormData();
  //   formData.append('Data', JSON.stringify(this.NewPersonalizeForm.value));

  //   if (this.productImageIndex.length === 0) {
  //     this.notify.showWarning('Warning', 'Product image is required');
  //     return;
  //   }

  //   if (this.cardImageIndex.length === 0) {
  //     this.notify.showWarning('Warning', 'Card image is required');
  //     return;
  //   }

  //   if (this.cardImageIndex.length > 0) {
  //     this.cardImageIndex.forEach(element => {
  //       formData.append('aFromCardFile', element);
  //       console.log('card image',this.cardImageIndex);
  //     });
  //   }

  //   if (this.productImageIndex.length > 0) {
  //     this.productImageIndex.forEach(element => {
  //       formData.append('aFormProdFile', element);
  //       console.log('product image',this.productImageIndex);
  //     });
  //   }

  //   if (formData) {
  //     this.spinner.show();
  //     this.personlizeService.addUpdateNewPersonalizie(formData).subscribe((res: any) => {

  //       if (res.ResponseCode == 200) {
  //         this.notify.showSuccess('Success', 'Product is personalized successfully');
  //         this.onHistoryPagination();
  //         this.NewPersonalizeForm.reset();
  //         this.spinner.hide();
  //         this.productImageFiles = [];
  //         this.cardImageFiles = [];
  //       }
  //       else {
  //         this.notify.showError('Error', res.Message);
  //         this.NewPersonalizeForm.reset();
  //         this.productImageFiles = [];
  //         this.cardImageFiles = [];
  //         this.spinner.hide();
  //       }
  //     }, (error) => {
  //       this.spinner.hide();
  //       this.NewPersonalizeForm.reset();
  //       return error;
  //     });
  //   }
  // }

  addNewPersonalization() {
  //  debugger
    this.NewPersonalizeForm.get('Provider_Client_Id_Entidade').setValue(this.selectedClient);
    this.NewPersonalizeForm.get('Id_Produto').setValue(this.selectedProduct);


    // const formData = new FormData();
    // formData.append('Data', JSON.stringify(this.NewPersonalizeForm.value));
    // Extract specific values from the form
    const specificValues = {
      HistoryPersonalizeName: this.NewPersonalizeForm.get('HistoryPersonalizeName').value,
      Code_Barras: this.NewPersonalizeForm.get('Code_Barras').value,
      Provider_Client_Id_Entidade: this.NewPersonalizeForm.get('Provider_Client_Id_Entidade').value,
      Id_Produto: this.NewPersonalizeForm.get('Id_Produto').value,
      N_ValorUnitario: this.NewPersonalizeForm.get('N_ValorUnitario').value,
      N_ValorFornecedor: this.NewPersonalizeForm.get('N_ValorFornecedor').value,
      N_ValorApresentacao: this.NewPersonalizeForm.get('N_ValorApresentacao').value,
      PresentationEN: this.NewPersonalizeForm.get('PresentationEN').value
    };

    // Create a FormData object
    const formData = new FormData();
    formData.append('Data', JSON.stringify(specificValues));

    // if (this.productImageIndex.length === 0) {
    //   this.notify.showWarning('Warning', 'Product image is required');
    //   return;
    // }

    // if (this.cardImageIndex.length === 0) {
    //   this.notify.showWarning('Warning', 'Card image is required');
    //   return;
    // }

    if (this.cardImageIndex.length > 0) {
      this.cardImageIndex.forEach(element => {
        formData.append('aFromCardFile', element);
        //console.log('card image', this.cardImageIndex);
      });
    }

    if (this.productImageIndex.length > 0) {
      this.productImageIndex.forEach(element => {
        formData.append('aFormProdFile', element);
        //console.log('product image', this.productImageIndex);
      });
    }
    if (this.DefaultImage.length > 0) {
      this.DefaultImage.forEach(element => {
        formData.append('aDefaultImage', element.image);
        // console.log('Default image', this.DefaultImage);
      });
    }
    

    if (formData) {
      this.spinner.show();
      this.personlizeService.addUpdateNewPersonalizie(formData).subscribe((res: any) => {

        if (res.ResponseCode == 200) {
          this.notify.showSuccess('Success', 'Product is personalized successfully');
          this.onHistoryPagination();
          this.NewPersonalizeForm.reset();
          this.productImageFiles = [];
          this.cardImageFiles = [];
          this.productImageIndex = []; // Reset the product image index
          this.cardImageIndex = []; // Reset the card image index
          this.spinner.hide();
        }
        else {
          this.notify.showError('Error', res.Message);
          this.NewPersonalizeForm.reset();
          this.productImageFiles = [];
          this.cardImageFiles = [];
          this.productImageIndex = []; // Reset the product image index
          this.cardImageIndex = []; // Reset the card image index
          this.spinner.hide();
        }
      }, (error) => {
        this.spinner.hide();
        this.NewPersonalizeForm.reset();
        this.productImageFiles = [];
        this.cardImageFiles = [];
        this.productImageIndex = []; // Reset the product image index
        this.cardImageIndex = []; // Reset the card image index
        return error;
      });
    }
  }


  //select the files in the product files drop zones and push it in the array of files 
  onselectProdctFiles(event) {
    this.productImageFiles.push(...event.addedFiles);
    //console.log('product image', this.productImageFiles);
    if (event.addedFiles && event.addedFiles[0]) {
      for (let i = 0; i < event.addedFiles.length; i++) {
        this.productImageIndex.push(event.addedFiles[i]);
        var reader = new FileReader();
        reader.readAsDataURL(event.addedFiles[i]);
      }
    }
  }

  //remove product files 
  onRemoveProdFiles(event) {
    this.productImageFiles.splice(this.productImageFiles.indexOf(event), 1);
  }

  //select the card images and push them in the array of files
  onSelectCardFiles(event) {
    this.cardImageFiles.push(...event.addedFiles);
    // console.log('card image', this.cardImageFiles);
    if (event.addedFiles && event.addedFiles[0]) {
      for (let i = 0; i < event.addedFiles.length; i++) {
        this.cardImageIndex.push(event.addedFiles[i]);
        var reader = new FileReader();
        reader.readAsDataURL(event.addedFiles[i]);
      }
    }
  }

  //remove card files
  onRemoveCardFiles(event) {
    this.cardImageFiles.splice(this.cardImageFiles.indexOf(event), 1);
  }

  searchProducts(search: any) {

    if (search.term && search.term.length >= 3) {
      this.filters.product.Value = search.term;
      this.onProductChange();
    }
  }

  onProductChange() {
    //debugger
    var config = {
      curPage: 1,
      perPage: 100,
      sortBy: "Codigo_Produto",
      direction: "desc",
      whereClauses: this.helperService.getAllFilters(this.filters)
    }
    this.productService.ProductPagination(config).subscribe((records: any) => {
      if (records.ResponseCode == 200) {
        this.ProductsList = records.Data.Data;
      }
      else {
        this.ProductsList = [];
      }
    }, (err) => {
      this.ProductsList = [];
      return err;
    });
  }

  getPage(page: number) {
    this.pageConfig.curPage = page;
    this.onPersonalizePagination();
  }

  //function not is for now 
  onPersonalizePagination() {
    if (this.pageConfig) {
      this.pageSize = this.pageConfig.perPage;
      this.page = this.pageConfig.curPage;
      this.pageConfig.whereClauses = [{
        Key: "Id_Produto",
        Value: this.selectedProduct.toString(),
        Operator: "0",
      },
      {
        Key: "iD_Entidade",
        Value: this.selectedClient.toString(),
        Operator: "0",
      }],

        this.spinner.show();
      this.personlizeService.productPersonalizePagination(this.pageConfig).subscribe((res: any) => {
        if (res.ResponseCode == 200) {
          this.personalizeProductDetials = res.Data.Data;
          this.spinner.hide();
        }
        else {
          this.spinner.hide();
        }
      }, (error) => {
        this.spinner.hide();
        return error;
      });
    }
  }


  //list function is not in use for now
  getHistoryList(clientId: any, productId?: any) {
    if (clientId != null && productId != null) {
      this.onFormInit();
      this.personlizeService.GetHistoryPersonalizationList(clientId, productId).subscribe((res: any) => {
        if (res.ResponseCode == 200) {
          this.personalizeHistoryList = res.Data;
          //console.log(this.personalizeHistoryList)
        }
        else {
          this.personalizeHistoryList = [];
        }
      }, (error) => {
        return error;
      });
    }
  }


  // onHistoryPagination() {

  //   if (this.selectedClient && this.selectedProduct) {
  //     this.getbyIDproduct();
  //     this.pageSize = this.pageConfig.perPage;
  //     this.page = this.pageConfig.curPage;
  //     this.pageConfig.whereClauses = [{
  //       Key: "Id_Produto",
  //       Value: this.selectedProduct?.toString(),
  //       Operator: "0",
  //     },
  //     {
  //       Key: "iD_Entidade",
  //       Value: this.selectedClient?.toString(),
  //       Operator: "0",
  //     }],

  //       this.spinner.show();
  //     this.personlizeService.HistoryPersonalizationPagination(this.pageConfig).subscribe((res: any) => {
  //       if (res.ResponseCode == 200) {
  //         this.spinner.hide();
  //         this.personalizeHistoryList = res.Data.Data;
  //         console.log(this.personalizeHistoryList)
  //         this.total = res.Data.Total;

  //         this.personalizeHistoryList.forEach((element) => {
  //           element.personalizedImages
  //             .filter(image => image.Image_Type == 'Products')
  //             .map(image => {
  //               this.productImagePath.push({ Id_HistoryProductPersonalization: image.IdHistoryProductPersonalization, ProductImagePath: image.ProductImagePath });
  //               element.productImg = image.ProductImagePath;
  //             });
  //             element.personalizedImages
  //             .filter(image => image.Image_Type == 'Cards')
  //             .forEach(image => {
  //               this.cardImagePath.push({
  //                 Id_HistoryProductPersonalization: image.IdHistoryProductPersonalization,
  //                 CardImagePath: image.CartImagePath
  //               });
  //               element.cardImg = image.CartImagePath;
  //               console.log('Card Image Path:', element.cardImg); 
  //             });
  //         });
  //       }
  //       else {
  //         this.spinner.hide();
  //         this.personalizeHistoryList = [];
  //       }
  //     }, (error) => {
  //       this.spinner.hide();
  //       this.personalizeHistoryList = [];
  //       return error;
  //     });
  //   }
  //   else {
  //     this.spinner.hide();
  //   }

  // }
  onHistoryPagination() {
    // debugger
    this.spinner.show();
  //   if (!this.selectedClient) {
  //     this.selectedProduct = null; 
  // }

    let pageConfig: any = {
      curPage: this.pageConfig.curPage,
      perPage: this.pageConfig.perPage,
      sortBy: "Id",
      direction: "desc",
      whereClauses: []
    };
    if (this.selectedClient && this.selectedProduct) {
      this.getbyIDproduct();
      pageConfig.whereClauses = [{
        Key: "Id_Produto",
        Value: this.selectedProduct.toString(),
        Operator: "0"
      },
      {
        Key: "iD_Entidade",
        Value: this.selectedClient.toString(),
        Operator: "0"
      }];
    } else {
      this.spinner.hide();
      return;
    }
    this.personlizeService.HistoryPersonalizationPagination(pageConfig).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.personalizeHistoryList = res.Data.Data;
        //console.log(this.personalizeHistoryList)
        this.total = res.Data.Total;
     
        this.personalizeHistoryList.forEach((element) => {
          element.personalizedImages
            .filter(image => image.Image_Type == 'Products')
            .map(image => {
              this.productImagePath.push({ Id_HistoryProductPersonalization: image.IdHistoryProductPersonalization, ProductImagePath: image.ProductImagePath });
              element.productImg = image.ProductImagePath;
            });

          element.personalizedImages
            .filter(image => image.Image_Type == 'Cards')
            .forEach(image => {
              this.cardImagePath.push({
                Id_HistoryProductPersonalization: image.IdHistoryProductPersonalization,
                CardImagePath: image.CartImagePath
              });
              element.cardImg = image.CartImagePath;
              // console.log('Card Image Path:', element.cardImg);
            });
        });
            this.personalizedProductImage=[];
            this.personalizeHistoryList.forEach(element => {
              element.personalizedImages.forEach(image => {
                  const imageType = image.Image_Type === 'Products' ? 'image' : 'card';
                  const imagePath = image.ProductImagePath || image.CartImagePath;

                  if (imagePath) {
                      this.personalizedProductImage.push({
                          id: image.IdHistoryProductPersonalization,
                          type: imageType,
                          image: imagePath,
                          selected: false
                      });
                  }
              });
          });


      } else {
        this.personalizeHistoryList = [];
      }
      //this.productCode = '';
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.personalizeHistoryList = [];
      console.error(error);
    });
  }

  onProductCodePagination() {
    let pageConfig: any = {
      curPage: this.pageConfig.curPage,
      perPage: this.pageConfig.perPage,
      sortBy: "Id",
      direction: "desc",
      whereClauses: []
    };
    if (this.productCode) {
      this.getbyIDproduct();
      pageConfig.whereClauses = [{
        Key: "Codigo_Produto",
        Value: this.productCode,
        Operator: "0"
      }];
    } else {
      this.spinner.hide();
      return;
    }

    this.personlizeService.historyProductCodePagination(pageConfig).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.productOptions = res.Data.Data;
        // console.log(this.productOptions)
        this.total = res.Data.Total;
      } else {
        this.productOptions = [];
      }
      //this.productCode = '';
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.productOptions = [];
      console.error(error);
    });
  }

  openImageModal(temp: any) {
    this.modalService.open(temp);
  }

  // makeIsPreferedPersonalize(product: any, client: any, id: any) {
  //   if (product != null && client != null && id != null) {
  //     this.spinner.show();
  //     this.personlizeService.perferredPersonalize(product, client, id).subscribe((res: any) => {
  //       if (res.ResponseCode == 200) {
  //         this.spinner.hide();
  //         this.notify.showSuccess('Success', res.Message);
  //       }
  //       else {
  //         this.spinner.hide();
  //         this.notify.showError('Error', res.Message);
  //       }
  //     }, (err) => {
  //       this.spinner.hide();
  //       return err;
  //     });
  //   }
  //   else {
  //     this.spinner.hide();
  //   }
  // }

  // makeIsPreferedPersonalize(prod: any, index: number) {
  //   if (prod != null) {
  //     if (this.currentPreferredIndex !== null && this.currentPreferredIndex !== index) {
  //       this.personalizeHistoryList[this.currentPreferredIndex].isPreferred = false;
  //     }

  //     this.personalizeHistoryList[index].isPreferred = true;
  //     this.currentPreferredIndex = index;

  //     const { Id_Produto, Provider_Client_Id_Entidade, Id_HistoryProductPersonalization } = prod;

  //     this.spinner.show();
  //     this.personlizeService.perferredPersonalize(Id_Produto, Provider_Client_Id_Entidade, Id_HistoryProductPersonalization,).subscribe(
  //       (res: any) => {
  //         this.spinner.hide();
  //         if (res.ResponseCode === 200) {
  //           this.notify.showSuccess('Success', res.Message);
  //         } else {
  //           this.notify.showError('Error', res.Message);
  //         }
  //       },
  //       (err) => {
  //         this.spinner.hide();
  //         return err;
  //       }
  //     );
  //   }
  // }
  makeIsPreferedPersonalize(prod: any, index: number) {
    if (prod != null) {
  
    
      const currentPreferredProduct = this.personalizeHistoryList.find(product => 
        product.HistoryProductPersonalization.some(item => item.IsPrefered)
      );
  
      
      if (currentPreferredProduct) {
        currentPreferredProduct.HistoryProductPersonalization.forEach(item => {
          item.IsPrefered = false;
        });
  
        const { Id_Produto, Provider_Client_Id_Entidade, Id_HistoryProductPersonalization } = currentPreferredProduct;
        this.personlizeService.perferredPersonalize(Id_Produto, Provider_Client_Id_Entidade, Id_HistoryProductPersonalization, false).subscribe(
          (res: any) => {
            if (res.ResponseCode !== 200) {
              this.notify.showError('Error', res.Message);
            }
          },
          (err) => {
            console.error('Error while unsetting previous preferred product:', err);
          }
        );
      }

      const isCurrentlyPreferred = prod.HistoryProductPersonalization.some(item => item.IsPrefered);

    
      prod.HistoryProductPersonalization.forEach(item => {
        item.IsPrefered = !isCurrentlyPreferred;
      });

    
      const { Id_Produto, Provider_Client_Id_Entidade, Id_HistoryProductPersonalization } = prod;
      const IsPrefered = prod.HistoryProductPersonalization[0].IsPrefered;

      this.spinner.show();
      this.personlizeService.perferredPersonalize(Id_Produto, Provider_Client_Id_Entidade, Id_HistoryProductPersonalization, IsPrefered).subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.ResponseCode === 200) {
            this.notify.showSuccess('Success', res.Message);
          } else {
            this.notify.showError('Error', res.Message);
          }
        },
        (err) => {
          this.spinner.hide();
          console.error('Error while setting preferred product:', err);
        }
      );
    }
  }


  //Do not Remove 
  // makeIsPreferedPersonalize(prod: any, index: number) {
  //   if (prod != null) {
  //     if (this.currentPreferredIndex !== null && this.currentPreferredIndex !== index) {
  //       this.personalizeHistoryList[this.currentPreferredIndex].HistoryProductPersonalization.forEach(item => {
  //         item.IsPrefered = false;
  //       });
  //     }
  //     // if (this.currentPreferredIndex !== null && this.currentPreferredIndex !== index) {
  //     //   this.personalizeHistoryList[this.currentPreferredIndex].isPreferred = false;
  //     // }

  //     // this.personalizeHistoryList[index].isPreferred = true;
  //     this.personalizeHistoryList[index].HistoryProductPersonalization.forEach(item => {
  //       item.IsPrefered = true;
  //     });
  //     this.currentPreferredIndex = index;
    
  //     const { Id_Produto, Provider_Client_Id_Entidade, Id_HistoryProductPersonalization } = prod;
  //     const IsPrefered = prod.HistoryProductPersonalization?.[0]?.IsPrefered || false;
      

  //     this.spinner.show();
  //     this.personlizeService.perferredPersonalize(Id_Produto, Provider_Client_Id_Entidade, Id_HistoryProductPersonalization, IsPrefered).subscribe(
  //       (res: any) => {
  //         this.spinner.hide();
  //         if (res.ResponseCode === 200) {
  //           this.notify.showSuccess('Success', res.Message);
  //         } else {
  //           this.notify.showError('Error', res.Message);
  //         }
  //       },
  //       (err) => {
  //         this.spinner.hide();
  //         return err;
  //       }
  //     );
  //   }
  // }


  DeleteFromTheHistory(id) {
    // debugger
    if (id) {
      this.spinner.show();
      this.personlizeService.DeleteHistroyPersonlization(id).subscribe((res: any) => {
        if (res.ResponseCode == 200) {
          this.spinner.hide();
          this.notify.showSuccess('Success', 'Product Personlization Deleted');
          this.onHistoryPagination();
        }
        else {
          this.spinner.hide();
          this.notify.showError('Error', res.Message);
        }
      }, (error) => {
        this.spinner.hide();
        return error;
      })
    }
    else {
      this.spinner.hide();
    }
  }

  getbyIDproduct() {

    if (this.productId == null) {
      if (this.selectedProduct != null) {
        this.productService.GetProductByID(this.selectedProduct, this.selectedClient).subscribe((res: any) => {
          this.spinner.show();
          if (res.ResponseCode == 200) {
            this.spinner.hide();
            this.productDetials = res.Data;
            this.selectedProduct = res.Data.ID_Produto;
            this.PrdocutdefaultImgs = res.Data.prodfiles;

            res.Data.personalizedImages
              .filter(images => images.Image_Type == 'Products')
              .map(image => this.productDetialsImage = image.ProductImagePath);
          }
          else {
            this.spinner.hide();
            this.notify.showWarning('Warning', 'Product Id not found');
          }
        }, (err) => {
          this.spinner.hide();
          return err;
        });
      }
    }
  }

  openProductModal() {
    if (this.productCode && this.productCode.trim() !== '') {
    this.modalService.open(this.productModal, { centered: true });
    this.onProductCodePagination();
    }
  }
  selectProduct(product: { HistoryProductPersonalization: Array<{ Codigo_Produto: string }>, Id_Produto: string }, modal: any) {
    // debugger
    if (product.HistoryProductPersonalization.length > 0) {
      this.productCode = product.HistoryProductPersonalization[0].Codigo_Produto;
      this.selectedProduct = product.Id_Produto;

      // console.log(product.Id_Produto, " Id");
      this.onHistoryPagination()
      // console.log(this.productCode, "product code");
    } else {
      console.warn("No product personalization data available.");
    }
    modal.close();
  }
  onProductSelectionChange() {
    if (this.selectedProduct) {
      this.getProductDetails();
      
    }
  }
  getProductDetails() {
    this.spinner.show();
    this.activeRoute.params.subscribe(params => {
        if (this.selectedClient && this.selectedProduct) {
            this.productService.GetOrderImage(this.selectedProduct, this.selectedClient).subscribe((res: any) => {
                if (res.ResponseCode === 200) {
                    this.ProductImageFiles = [];
                    this.ProductImage = [];

                    res.Data.forEach(item => {
                      if (item.IsPersonalized == false) {
                        const imagePath = item.ImagePath;
                        const isImage = this.isImage(imagePath);
                        const isVideo = this.isVideo(imagePath);
                      //   const fileData = {
                      //     image: isImage ? imagePath : null,
                      //     video: isVideo ? imagePath : null,
                      //     type: isImage ? 'image' : 'video',
                      //     Id: item.Id,
                      //     ImageType: item.ImageType,
                      //     IsPersonalized: item.IsPersonalized,
                      //     selected: false 
                      // };
                        if (imagePath) {
                            if (isImage) {
                                this.ProductImageFiles.push({ image: imagePath, type: 'image' });
                            } else if (isVideo) {
                                this.ProductImageFiles.push({ video: imagePath, type: 'video' });
                            }
                        }
                      }
                    });
                    
                    // res.Data.forEach(item => {
                    //   if (item.IsPersonalized == true) {
                    //     const imagePath = item.ImagePath;
                    //     const isImage = this.isImage(imagePath);
                    //     const isVideo = this.isVideo(imagePath);

                    //     if (imagePath) {
                    //         if (isImage) {
                    //             this.ProductImage.push({ image: imagePath, type: 'image' });
                    //         } else if (isVideo) {
                    //             this.ProductImage.push({ video: imagePath, type: 'video' });
                    //         }
                    //     }
                    //   }
                    // });


                    // Set default image as the first image in the list, if available
                    if (this.ProductImageFiles.length > 0) {
                        this.defaultImagePath = this.ProductImageFiles[0]?.image || null;
                    }

                    this.spinner.hide();
                }
            }, error => {
                this.spinner.hide();
                console.error("Error fetching product images:", error);
            });
        }
    });
}

toggleImage(file: any) {
    const index = this.DefaultImage.indexOf(file);
    if (index === -1) {
        this.DefaultImage.push(file);
        // console.log(this.productImageIndex);
    } else {
        this.DefaultImage.splice(index, 1);
    }
}
  
  // toggleImage(file: any) {
  //   debugger
  //   if (file.selected) {
  //     if (!this.productImageIndex.includes(file)) {
  //       this.productImageIndex.push(file);
  //       // console.log(this.productImageIndex);
  //     }
  //   } else {
  //     this.productImageIndex = this.productImageIndex.filter(img => img !== file);
  //   }
  // }

  isSelected(image: any): boolean {
    return this.selectedImages.includes(image);
  }

  getExension(path) {
    if (path) {
      return path.split('.').pop()?.toLowerCase();
    } else {
      return null;
    }
  }
  isImage(filePath: string): boolean {
    if (!filePath) {
      return false;
    }
    const extension = this.getExension(filePath);
    return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension);
  }
  isVideo(filePath: string): boolean {
    const extension = this.getExension(filePath);
    return ['mp4', 'avi', 'mov'].includes(extension);
  }
 
  setCurrentIndex(index: number): void {
    this.currentIndex = index;
  }
  prevImage(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.ProductImageFiles.length - 1;
    }
  }

  nextImage(): void {
    if (this.currentIndex < this.ProductImageFiles.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
  }
  setCurrentIndexPersonalized(index: number): void {
    this.currentIndexPersonalized = index;
  }
  prevImageProduct(): void {
    if (this.currentIndexPersonalized > 0) {
        this.currentIndexPersonalized--;
    } else {
        this.currentIndexPersonalized = this.personalizedProductImage.length - 1;
    }
}

nextImageProduct(): void {
    if (this.currentIndexPersonalized < this.personalizedProductImage.length - 1) {
        this.currentIndexPersonalized++;
    } else {
        this.currentIndexPersonalized = 0;
    }
}

 // findImagePath(element: any): string | null {
  //   if (element.Prodfilepath) {
  //     return element.Prodfilepath;
  //   }
  //   if (element.ProductImagePath) {
  //     return element.ProductImagePath;
  //   }
  //   if (element.Cartfilepath) {
  //     return element.Cartfilepath;
  //   }
  //   if (element.defaultFilePath) {
  //     return element.defaultFilePath;
  //   }
  //   if (element.CardfilePaths) {
  //     return element.CardfilePaths;
  //   }

  //   return null;
  // }
  // isImageOrVideo(path, type): boolean {
  //   if (path) {
  //     var ext = this.getExension(path);
  //     var ImgExts = ["jpg", "jpeg", "png", "gif", "webp"];
  //     var VidExts = ["mp4", "mov", "avi"];
  //     if (type == 1) {
  //       if (ImgExts.includes(ext)) return true;
  //       else return false;
  //     } else if (type == 2) {
  //       if (VidExts.includes(ext)) return true;
  //       else return false;
  //     }
  //     return false;
  //   } else {
  //     return false;
  //   }
  // }

}
