import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customNumberFormat'
})
export class CustomNumberFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (value === null || value === undefined) return '';
    const isInteger = Number.isInteger(value);

    // Format differently for integers and decimals
    return isInteger
      ? value.toLocaleString('de-DE') // No decimal places for integers
      : value.toLocaleString('de-DE', { 
          minimumFractionDigits: 3, 
          maximumFractionDigits: 3 
        });
  }
    // Format the number with a period for thousands separator and comma for decimal
    // return value.toLocaleString('de-DE', { minimumFractionDigits: 3,  maximumFractionDigits: 3  });
   
  }

