import { DecimalPipe } from "@angular/common";
import {
  Component,
  HostListener,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import { SortEvent } from "src/app/shared/directives/shorting.directive";
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from "src/app/shared/service/table.service";
import { ProductService } from "src/app/shared/service/product.service";
import { ProviderService } from "src/app/shared/service/provider.service";
import { Router } from "@angular/router";
import { NotifyService } from "../../../../shared/service/notify.service";
import { UserService } from "../../../../shared/service/user.service";
import {
  NgbCarouselConfig,
  NgbDateStruct,
  NgbModal,
  NgbSlideEvent,
  NgbCarousel,
} from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationDialogService } from "src/app/shared/service/confirmation-dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { HelperService } from "src/app/shared/service/helper.service";
import { ChangeDetectorRef } from "@angular/core";
import Swal from "sweetalert2";
import { CustomNumberFormatPipe } from "src/app/pipes/customNumberFormat.pipe";
import { SharedClientServiceService } from "src/app/shared-client-service.service";
import { NgSelectComponent } from "@ng-select/ng-select";
@Component({
  selector: "app-digital-list",
  templateUrl: "./digital-list.component.html",
  styleUrls: ["./digital-list.component.scss"],
  providers: [TableService, DecimalPipe, CustomNumberFormatPipe],
  encapsulation: ViewEncapsulation.None,
})
export class DigitalListComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  @ViewChild("carousel", { static: false }) carousel: NgbCarousel;
  @ViewChildren("videoElement") videoElements: QueryList<ElementRef>;
  @ViewChild('statusSelect') statusSelect: NgSelectComponent;

  public model: NgbDateStruct;
  public modelFooter: NgbDateStruct;
  public digital_categories = [];
  public searchText;

  imageObject: Array<any> = [];
  combinedPaths: string[] = [];
  minEndDate: string = "";

  pageConfig: any = {
    filter: {
      iD_Entidade: "0",
      ProductNameOrCode: "",
      ProductName: "",
      PriceFrom: "",
      PriceTo: "",
      StarDate: "",
      EndDate: "",
      iD_Familias: [],
      iD_Materials: [],
      ProviderId: [],
      IsActiveProducts: true,
      status: [],
    },
    pageSize: 12,
    p: 1,
  };
  statusOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Deactive', value: 'deactive' },
    { label: 'To be Approved', value: 'ToBeApproved' },
    { label: 'Eliminated', value: 'eliminated' }
  ];
  

  ShoppingCart: any = {
    Nome_Carrinho: "",
    ID_Produto: 0,
    Q_Produto: 0,
    Q_Caixas: 0,
    ID_Cliente: 0,
  };

  showFactorDevison: boolean = false;
  isDisable: boolean = false;
  IsAddCart: boolean = false;
  isLoading: boolean = false;

  tableItem$: any[] = [];
  products: any[] = [];

  prodRows: number;
  total: number = 0;
  docHeight: number = 0;
  windowBottom: number = 0;

  Clients: any[] = [];
  User: any;
  page: any;
  Carts: any;
  total$: any;
  Family: any;
  qBoxes: any;
  divSize: any;
  qProduct: any;
  Material: any;
  pageSize: any;
  Provider: any;
  Familias: any;
  Materials: any;
  FinalTotal: any;
  CurrencySymb: any;
  ProductName: any;
  newCartString: any;
  factorDevison: any;
  debounceTimeout: any;
  defaultSelected: any;
  selectedCartName: any;
  shoppingCartName: any;
  ShoppingCartItems: any;
  productQuantities: number[] = [];
  callCount: any;
  //note: it will handle the filters if user have setup something on default setting
  customWhereClause: any;
  unitForm: FormGroup | any;
  public defaultImage = "../assets/images/product-list/NoImage.jpg";
  public mergedMediaPaths: string[] = [];

  personalizedImagesList: any[] = [];
  prodfilesimages: any[] = [];
  Images: any[] = [];
  videos: any[] = [];
  extension: any;
  boxUnitArr: any[] = [];
  singleProductReturned = false;
  isFirstLoad = true;
  selectedClientName: string = '';

  constructor(
    private Route: Router,
    public service: TableService,
    public helper: HelperService,
    private notify: NotifyService,
    private modalService: NgbModal,
    public productService: ProductService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private provider: ProviderService,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private changref: ChangeDetectorRef,
    public spinnerService: NgxSpinnerService,
    public confirmationService: ConfirmationDialogService,
    public customNumberPricetPipe: CustomNumberFormatPipe,
    private sharedClientService: SharedClientServiceService,
    config: NgbCarouselConfig
  ) {
    config.animation = false;
    config.keyboard = false;
    // config.wrap = false;
    config.showNavigationArrows = false;
    this.boxUnitArr = Array(10)
      .fill(0)
      .map(() => ({ units: "", boxes: "" }));
  }

  @HostListener("window: scroll", ["event"])
  onScroll(event: Event) {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body,
        html = document.documentElement;

      this.docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.offsetHeight,
        html.scrollHeight
      );

      this.windowBottom = window.scrollY + windowHeight;
      localStorage.setItem("scrollPosition", this.windowBottom.toString());

      if (this.windowBottom > this.docHeight - 2.5 && !this.isLoading) {
        this.productService.page++;
        this.productService.searchTerm.iD_Entidade =
          this.pageConfig.filter.iD_Entidade;
        this.productService.CallPagination();
      }
    }, 200);
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });
    this.productService.sortColumn = column;
    this.productService.sortDirection = direction;
  }

  //formInit for the units and boxes in the product
  onFormInIt() {
    this.unitForm = new FormGroup({
      units: new FormControl(""),
      boxes: new FormControl(""),
    });
  }

  //Set the currency symbol from the localStorage
  onSetCurrencySymb() {
    this.CurrencySymb = localStorage.getItem("Currency");
    if (this.CurrencySymb == "USD") {
      this.CurrencySymb = "$";
    } else if (this.CurrencySymb == "Yuan") {
      this.CurrencySymb = "¥";
    } else if (this.CurrencySymb == "Euro") {
      this.CurrencySymb = "€";
    }
  }

  onGetUser() {
    this.userService.user.subscribe((user: any) => {
      if (user) {
        this.User = user;
        this.pageConfig.filter.iD_Entidade = +this.User.iD_Entidade; // companyIdEntidade
      }
    });
  }

  //Set the Custom Where Clause in localStorage
  onGetCustomWhereClause() {
    var customWhereClause = JSON.parse(
      localStorage.getItem("customWhereClause")
    );
    if (customWhereClause) {
      this.prodRows = customWhereClause.linha;
      this.onProdRowsChange();
    }
  }

  onGetProducts() {
    // this.productService.tableItem$.subscribe((data: any[]) => {
    //   if (data) {
    //     this.tableItem$ = [...this.tableItem$, ...data];
    //     // console.log(this.tableItem$)
    //     for (let i = 0; i < this.tableItem$.length; i++) {
    //       const item = this.tableItem$[i];
    //       // item.CardfilePaths = [];

    //       item.combineddata = [];
    //       if (item.prodfiles && item.prodfiles.length > 0) {
    //         item.combineddata = item.combineddata.concat(item.prodfiles);
    //         // console.log(item.prodfiles);
    //       }
    //       if (item.personalizedImages && item.personalizedImages.length > 0) {
    //         item.combineddata = item.combineddata.concat(
    //           item.personalizedImages
    //         );
    //       }
    //       if (item.CardfilePaths && item.CardfilePaths.length > 0) {
    //         item.combineddata = item.combineddata.concat(item.CardfilePaths);
    //         //console.log(item.CardfilePaths);
    //       }
    //       if (item.Cartfilepath && item.Cartfilepath.length > 0) {
    //         item.combineddata = item.combineddata.concat(item.Cartfilepath);
    //         // console.log(item.Cartfilepath);
    //       }
    //       item.CardfilePaths = [];

    //       item.combineddata.forEach((element) => {
    //         const imagePath = this.findImagePath(element);
    //         if (imagePath) {
    //           if (this.isImageOrVideo(imagePath, 1)) {
    //             item.CardfilePaths.push({
    //               image: imagePath,
    //               thumbImage: imagePath,
    //             });
    //             // console.log(item.CardfilePaths);
    //           } else {
    //             item.CardfilePaths.push({
    //               video: imagePath,
    //             });
    //           }
    //         }
    //       });
    //       this.boxUnitArr.push({ boxes: "", units: "" });
    //     }
    //   }
    // });

    this.productService.tableItem$.subscribe((data: any[]) => {
      if (data) {
        // Create a Set to track unique identifiers
        const uniqueIds = new Set(this.tableItem$.map((item) => item.ID_Produto)); // Assuming 'id' is the unique identifier

        // Filter the incoming data to only include unique items
        const filteredData = data.filter((item) => !uniqueIds.has(item.ID_Produto));

        // Append filtered data to tableItem$
        this.tableItem$ = [...this.tableItem$, ...filteredData];

        for (let i = 0; i < this.tableItem$.length; i++) {
          const item = this.tableItem$[i];
          item.combineddata = [];

          if (item.prodfiles && item.prodfiles.length > 0) {
            item.combineddata = item.combineddata.concat(item.prodfiles);
          }
          if (item.personalizedImages && item.personalizedImages.length > 0) {
            item.combineddata = item.combineddata.concat(
              item.personalizedImages
            );
          }
          if (item.CardfilePaths && item.CardfilePaths.length > 0) {
            item.combineddata = item.combineddata.concat(item.CardfilePaths);
          }
          if (item.Cartfilepath && item.Cartfilepath.length > 0) {
            item.combineddata = item.combineddata.concat(item.Cartfilepath);
          }

          // Clear CardfilePaths before populating it again
          item.CardfilePaths = [];

          item.combineddata.forEach((element) => {
            const imagePath = this.findImagePath(element);
            if (imagePath) {
              if (this.isImageOrVideo(imagePath, 1)) {
                item.CardfilePaths.push({
                  image: imagePath,
                  thumbImage: imagePath,
                });
              } else {
                item.CardfilePaths.push({
                  video: imagePath,
                });
              }
            }
          });
          this.boxUnitArr.push({ boxes: "", units: "" });
        }
      }
    });

    this.total$ = this.productService.total$;
    this.productService.total$.subscribe((res: any) => {
      this.total = res;
    });

    this.page = this.productService.page;
    this.pageSize = this.productService.pageSize;
  }

  // onGetProducts() {
  //   debugger
  //   this.productService.tableItem$.subscribe((data: any[]) => {
  //     if (this.isFirstLoad) {
  //       this.processInitialData(data);
  //       this.isFirstLoad = false; // Set the flag to false after the first load
  //     } else {
  //       this.processFilteredData(data);
  //     }
  //   });

  //   this.total$ = this.productService.total$;
  //   this.productService.total$.subscribe((res: any) => {
  //     this.total = res;
  //   });

  //   this.page = this.productService.page;
  //   this.pageSize = this.productService.pageSize;
  // }

  processInitialData(data: any[]) {
    this.tableItem$ = [...data];
    //console.log('Initial Data:', this.tableItem$);

    this.tableItem$.forEach((item) => {
      this.processItemData(item);
    });
  }

  processFilteredData(data: any[]) {
    this.tableItem$ = [...data];
    //console.log('Filtered Data:', this.tableItem$);

    this.tableItem$.forEach((item) => {
      this.processItemData(item);
    });
  }

  processItemData(item: any) {
    item.combineddata = [];

    if (item.prodfiles && item.prodfiles.length > 0) {
      item.combineddata = item.combineddata.concat(item.prodfiles);
    }
    if (item.personalizedImages && item.personalizedImages.length > 0) {
      item.combineddata = item.combineddata.concat(item.personalizedImages);
    }
    if (item.CardfilePaths && item.CardfilePaths.length > 0) {
      item.combineddata = item.combineddata.concat(item.CardfilePaths);
    }
    if (item.Cartfilepath && item.Cartfilepath.length > 0) {
      item.combineddata = item.combineddata.concat(item.Cartfilepath);
    }

    item.CardfilePaths = [];

    item.combineddata.forEach((element) => {
      const imagePath = this.findImagePath(element);
      if (imagePath) {
        if (this.isImageOrVideo(imagePath, 1)) {
          item.CardfilePaths.push({
            image: imagePath,
            thumbImage: imagePath,
          });
        } else {
          item.CardfilePaths.push({
            video: imagePath,
          });
        }
      }
    });

    this.boxUnitArr.push({ boxes: "", units: "" });
  }

  findImagePath(element: any): string | null {
    if (element.Prodfilepath) {
      return element.Prodfilepath;
    }
    if (element.ProductImagePath) {
      return element.ProductImagePath;
    }
    if (element.CardfilePaths) {
      return element.CardfilePaths;
    }
    if (element.Cartfilepath) {
      return element.Cartfilepath;
    }

    return null;
  }

  filterItems(arr) {
    var a = arr.filter((x) => x.ProductImagePath != null);
    return a;
  }

  ngOnInit() {
    this.onFormInIt();
    this.onSetCurrencySymb();
    this.onGetUser();
    this.onGetProducts();
    this.onGetCustomWhereClause();
    this.GetAllFamilias();
    this.GetAllMaterial();
    this.getAllCartsName();
    this.GetAllClients();
    // this.getFactoryDevison(this.pageConfig.filter.iD_Entidade);
    const savedFilters = localStorage.getItem('filterData');
    if (savedFilters) {
      this.pageConfig.filter = JSON.parse(savedFilters);
    }
    this.Provider = this.provider.providers$;
    this.pageConfig.filter.iD_Entidade = +localStorage.getItem("iD_Entidade");

    window.addEventListener('beforeunload', this.handleBeforeUnload);
    const manualReload = sessionStorage.getItem('manualReload');
    if (manualReload === 'true') {
      // Clear the manual reload flag
      sessionStorage.removeItem('manualReload');
      this.clearAllFiltersAndData();
    }
  }
  

  handleBeforeUnload = (event: BeforeUnloadEvent) => {
    // Set a flag in sessionStorage to indicate manual reload
    sessionStorage.setItem('manualReload', 'true');
  }

  ngAfterViewInit() {
    // Restore the scroll position from localStorage
    const savedScrollPosition = localStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
    }
  }

  ngOnDestroy() {
    // Clear the stored scroll position when leaving the page
    localStorage.removeItem("scrollPosition");
    this.pageConfig.filter.ProductNameOrCode = "";
    this.pageConfig.filter.ProductName = "";
    this.pageConfig.filter.PriceFrom = "";
    this.pageConfig.filter.PriceTo = "";
    this.pageConfig.filter.StarDate = "";
    this.pageConfig.filter.EndDate = null;
    this.pageConfig.filter.PriceFrom = null;
    this.pageConfig.filter.iD_Entidade = null;
    this.pageConfig.filter.ProviderId = null;
    this.pageConfig.filter.iD_Materials = [[]];
    this.pageConfig.filter.iD_Familias = [[]];
    this.clearAllFiltersAndData();
    // window.removeEventListener('beforeunload', () => {
    //   this.clearLocalStoarge();
    // });
  }

  getPage(page: number) {
    this.productService.page = page;
    this.productService.sortDirection = "desc";
    this.productService.sortColumn = "ID_Produto";
    this.productService.CallPagination();
  }

  // filterBy(pageSize?: number) {
  //   this.spinner.show();
  //   this.tableItem$ = [];
  //   if (pageSize) this.pageSize = pageSize;
  //   this.productService.sortDirection = "desc";
  //   this.productService.sortColumn = this.service.sortColumn;
  //   this.productService.page = 1;
  //   this.productService.searchTerm.iD_Entidade =
  //     this.pageConfig.filter.iD_Entidade; //client id entidade
  //   this.productService.searchTerm.ID_Entidade =
  //     this.pageConfig.filter.ProviderId; //provider id entidade
  //   this.productService.searchTerm.ProductNameOrCode =
  //     this.pageConfig.filter.ProductNameOrCode; //product code
  //   this.productService.searchTerm.ProductName =
  //     this.pageConfig.filter.ProductName;
  //   this.productService.searchTerm.PriceFrom = this.pageConfig.filter.PriceFrom;
  //   this.productService.searchTerm.PriceTo = this.pageConfig.filter.PriceTo;
  //   this.productService.searchTerm.StarDate = this.pageConfig.filter.StarDate;
  //   this.productService.searchTerm.EndDate = this.pageConfig.filter.EndDate;
  //   this.productService.searchTerm.iD_Familias =
  //     this.pageConfig.filter.iD_Familias;
  //   this.productService.searchTerm.iD_Materials =
  //     this.pageConfig.filter.iD_Materials;
  //   this.productService.searchTerm.IsActiveProducts =
  //     this.pageConfig.filter.IsActiveProducts;
  //   this.productService.pageSize = this.pageConfig.pageSize;
  //   this.pageSize = this.productService.pageSize;
  //   this.productService.CallPagination();
  //   this.spinner.hide();
  // }
  onStatusChange(selectedValue: string) {
    // Reset the `status` array to contain only the selected value
    this.pageConfig.filter.status = [selectedValue];
    this.filterBy();
  }
  

  filterBy(pageSize?: number) {
    // debugger
    localStorage.setItem('filterData', JSON.stringify(this.pageConfig.filter));
    // this.pageConfig.filter.IsActiveProducts = undefined;
    this.pageConfig.filter.ToBeApproved = undefined;
    // this.pageConfig.filter.eliminated = undefined;
    if (!this.pageConfig.filter.iD_Entidade) {
      this.pageConfig.filter.IsActiveProducts = false;
      return;
    }
    this.spinner.show();
    this.tableItem$ = [];
    this.total$ = 0;
    this.productService.settotal(0);
    if (pageSize) this.pageConfig.pageSize = pageSize;
    this.productService.sortDirection = "asc";
    this.productService.sortColumn = this.productService.sortColumn;
    this.productService.page = 1;
    // console.log(this.pageConfig.pageSize)

    this.productService.searchTerm.iD_Entidade =
      this.pageConfig.filter.iD_Entidade;
    this.productService.searchTerm.ID_Entidade =
      this.pageConfig.filter.ProviderId;
    this.productService.searchTerm.ProductNameOrCode =
      this.pageConfig.filter.ProductNameOrCode;
    this.productService.searchTerm.ProductName =
      this.pageConfig.filter.ProductName;
    this.productService.searchTerm.PriceFrom = this.pageConfig.filter.PriceFrom;
    this.productService.searchTerm.PriceTo = this.pageConfig.filter.PriceTo;
    this.productService.searchTerm.StarDate = this.pageConfig.filter.StarDate;
    this.productService.searchTerm.EndDate = this.pageConfig.filter.EndDate;
    this.productService.searchTerm.iD_Familias =
      this.pageConfig.filter.iD_Familias;
    this.productService.searchTerm.iD_Materials =
      this.pageConfig.filter.iD_Materials;
    // this.productService.searchTerm.IsActiveProducts =
    //   this.pageConfig.filter.IsActiveProducts;
    if (this.pageConfig.filter.status.length === 0) {
      this.pageConfig.filter.IsActiveProducts = true;
    } else {
      if (this.pageConfig.filter.status.includes('active')) {
        this.pageConfig.filter.IsActiveProducts = true;
      } else if (this.pageConfig.filter.status.includes('deactive')) {
        this.pageConfig.filter.IsActiveProducts = false;
      }
      else if(this.pageConfig.filter.status.includes('ToBeApproved')) {
        this.pageConfig.filter.ToBeApproved = true;
      }
      // else if(this.pageConfig.filter.status.includes('eliminated')) {
      //   this.pageConfig.filter.eliminated = true;
      //   delete this.pageConfig.filter.IsActiveProducts;
      // }
    }

      this.productService.searchTerm.status= this.pageConfig.filter.status,
    // this.productService.pageSize = this.pageConfig.pageSize;
    this.productService.searchTerm = {...this.pageConfig.filter };
    this.productService._state.pageSize = this.pageConfig.pageSize;
    //console.log('service', this.productService.pageSize);
    this.productService.onClearList();
    this.productService.CallPagination();
     this.statusSelect.close();
    this.spinner.hide();
  }
  updateLocalStorage() {
    localStorage.setItem('filterData', JSON.stringify(this.pageConfig.filter));
  }

  // clearLocalStoarge() {
  //   localStorage.removeItem('filterData');
  //   // localStorage.removeItem("iD_Entidade");
  // }
  
  
  onCheckboxChange(event: any) {
    if (!this.pageConfig.filter.iD_Entidade) {
      event.preventDefault();
      this.pageConfig.filter.IsActiveProducts = false;
      this.notify.showWarning(
        "Client Selection Required",
        "Please select a client first"
      );
      event.target.checked = false;
      this.pageConfig.filter.IsActiveProducts = false;
    } else {
      this.pageConfig.filter.IsActiveProducts = event.target.checked;
      this.filterBy();
    }
  }

  clearAllFiltersAndData(isManual:boolean= false) {
    //To Clear Filters on the button Click:
    this.pageConfig.filter.ProductNameOrCode = "";
    this.pageConfig.filter.ProductName = "";
    this.pageConfig.filter.PriceTo = "";
    this.pageConfig.filter.StarDate = "";
    this.pageConfig.filter.EndDate = null;
    this.pageConfig.filter.PriceFrom = null;
    //this.pageConfig.filter.iD_Entidade = "0";
    this.pageConfig.filter.iD_Entidade = localStorage.getItem("iD_Entidade");
    // console.log(this.pageConfig.filter.iD_Entidade);
    this.pageConfig.filter.ProviderId = null;
    this.pageConfig.filter.iD_Materials = [[]];
    this.pageConfig.filter.iD_Familias = [[]];
    // this.pageConfig.filter = {};  // Reset your filter object
    // localStorage.removeItem('filterData');  // Remove from localStorage
    //this.filterBy();
    if (isManual) {
       localStorage.removeItem('filterData'); 
       localStorage.removeItem('iD_Entidade')
      window.location.reload();
  }
  }

  GetAllFamilias() {
    this.productService.GetAllFamilies().subscribe(
      (res: any) => {
        if (res.ResponseCode == 200) {
          this.Familias = res.Data;
        } else {
          this.Familias = [];
        }
      },
      (error) => {
        this.Familias = [];
      }
    );
  }

  GetAllMaterial() {
    this.productService.GetAllMaterial().subscribe(
      (res: any) => {
        if (res.ResponseCode == 200) {
          this.Materials = res.Data;
        } else {
          this.Materials = [];
        }
      },
      (error) => {
        this.Materials = [];
      }
    );
  }

  GoToDetail(id) {
    this.Route.navigate(["products/digital/digital-detail", id]);
  }

  Update(id) {
    this.Route.navigate(["products/digital/digital-edit-product/update/", id]);
  }

  addProduct(products: any) {
    if (!this.pageConfig.filter.iD_Entidade) {
      this.notify.showWarning(
        "Notify.Warning",
        "Please select a client before adding products."
      );
      return;
    }
    //intializing the object of the shopping cart
    this.ShoppingCart.ID_Produto = products?.ID_Produto;
    this.ShoppingCart.Q_Produto = this.qProduct;
    this.ShoppingCart.Q_Caixas = this.qBoxes;
    this.ShoppingCart.Nome_Carrinho = this.shoppingCartName;
    this.ShoppingCart.ID_Cliente = this.pageConfig.filter.iD_Entidade;

    //check if the shopping cart is present or not
    if (
      this.shoppingCartName === "" ||
      this.shoppingCartName == null ||
      this.shoppingCartName === undefined
    ) {
      this.notify.showWarning(
        "Notify.Warning",
        "Please Select a Cart to add Products"
      );
    } else {
      //check if the units and boxes are empty
      if (
        (this.ShoppingCart.Q_Produto != undefined &&
          this.ShoppingCart.Q_Produto != 0) ||
        (this.ShoppingCart.Q_Caixas != undefined &&
          this.ShoppingCart.Q_Caixas != 0)
      ) {
        //check the quantity of Enterd units if less then avaliabe units
        if (this.qProduct < products?.N_ItensPorCaixa) {
          Swal.fire({
            icon: "info",
            text: `The reference (${products?.Codigo_Produto} - ${products?.ProductName}) is ${products?.N_ItensPorCaixa} pcs by box.
            Since you order ${this.qProduct} pcs, we rounded to ${products?.N_ItensPorCaixa} pcs to complete the boxes.`,
            allowOutsideClick: false,
            confirmButtonText: "Close",
            confirmButtonColor: "red",
            showConfirmButton: true,
          }).then(() => {
            this.ShoppingCart.Q_Produto = products?.N_ItensPorCaixa;
            this.insertIntoShoppingCart(this.ShoppingCart);
            this.clearInputs(products.ID_Produto);
          });
        }

        //check the quantity of Enterd units if greather then avaliabe units
        else if (this.qProduct > products?.N_ItensPorCaixa) {
          //divide the units with the Entered quantity of products round off their sum and add into the cart
          var result = this.qProduct / products?.N_ItensPorCaixa;
          var roundedNumber = Math.ceil(result);
          var roundNumber = roundedNumber * products?.N_ItensPorCaixa;
          if (this.qProduct == roundNumber) {
            // console.log(
            //   "Enter units is equal to rounded number",
            //   this.qProduct,
            //   roundNumber
            // );
            this.ShoppingCart.Q_Produto = roundNumber;
            this.insertIntoShoppingCart(this.ShoppingCart);
            this.clearInputs(products.ID_Produto);
          } else {
            Swal.fire({
              icon: "info",
              text: `The reference (${products?.Codigo_Produto} - ${products?.ProductName}) is ${products?.N_ItensPorCaixa} pcs by box.
              Since you order ${this.qProduct} pcs, we rounded to ${roundNumber} pcs to complete the boxes.`,
              allowOutsideClick: false,
              confirmButtonText: "Close",
              confirmButtonColor: "red",
              showConfirmButton: true,
            }).then(() => {
              this.ShoppingCart.Q_Produto = roundNumber;
              this.insertIntoShoppingCart(this.ShoppingCart);
              this.clearInputs(products.ID_Produto);
            });
          }
        } else {
          if (this.qProduct == products?.N_ItensPorCaixa) {
            this.insertIntoShoppingCart(this.ShoppingCart);
            this.clearInputs(products.ID_Produto);
          }
        }
      }

      //error shows if the units are not input correctly
      else {
        this.notify.showWarning(
          "Notify.Warning",
          "ProdValWarnings.EnterCorrectInput"
        );
      }
    }
  }

  addBoxesInCart(products: any) {
    if (products) {
      if (this.qBoxes == null || this.qBoxes == 0 || this.qBoxes == undefined) {
        this.notify.showWarning(
          "Notify.Warning",
          "ProdValWarnings.EnterCorrectInput"
        );
      } else {
        this.ShoppingCart.ID_Produto = products?.ID_Produto;
        this.ShoppingCart.Q_Produto = this.qProduct;
        this.ShoppingCart.Q_Caixas = this.qBoxes;
        this.ShoppingCart.Nome_Carrinho = this.shoppingCartName;
        this.ShoppingCart.ID_Cliente = this.pageConfig.filter.iD_Entidade;

        this.ShoppingCart.Q_Caixas = this.qBoxes;
        this.ShoppingCart.Q_Produto =
          this.ShoppingCart.Q_Caixas * products?.N_ItensPorCaixa;

        this.insertIntoShoppingCart(this.ShoppingCart);
        this.clearInputs(products.ID_Produto);
      }
    }
  }
  private clearInputs(productId: number) {
    const index = this.boxUnitArr.findIndex(
      (item) => item.ID_Produto === productId
    );
    if (index !== -1) {
      this.boxUnitArr[index].units = "";
      this.boxUnitArr[index].boxes = "";
    }
  }

  getAllCartsName() {
    this.productService.getAllCarts().subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.Carts = res.Data;
        if (!this.shoppingCartName) this.shoppingCartName = this.Carts[0];
        if (this.shoppingCartName && this.shoppingCartName != "undefined") {
          this.onGetShoppingCartItems(this.shoppingCartName);
        }
        // this.getShoppingCart(this.shoppingCartName);
      }
    });
  }

  onGetShoppingCartItems(shoppingCartNam: any) {
    this.shoppingCartName = shoppingCartNam;

    this.productService.searchTerm.iD_Entidade =
      this.pageConfig.filter.iD_Entidade;
    if (
      shoppingCartNam != null &&
      shoppingCartNam != "" &&
      shoppingCartNam != "undefined"
    )
      this.productService
        .getShoppingCartByName(this.shoppingCartName)
        .subscribe((res: any) => {
          if (res.ResponseCode == 200) {
            this.IsAddCart = false;
            this.ShoppingCartItems = res.Data.ShoppingCartList;
            this.FinalTotal = res.Data.FinalTotal;
            this.newCartString = null;
          }
        });
  }

  getShoppingCart(name: any) {
    this.shoppingCartName = name;
    var isExists = this.Carts.find((x) => x == name);
    console;
    if (this.shoppingCartName && this.shoppingCartName != "undefined") {
      this.productService
        .getShoppingCartByName(this.shoppingCartName)
        .subscribe((res: any) => {
          if (res.ResponseCode == 200) {
            this.IsAddCart = false;
            this.ShoppingCartItems = res.Data.ShoppingCartList;
            this.FinalTotal = res.Data.FinalTotal;
            this.newCartString = null;
            if (!isExists) {
              this.notify.showSuccess(
                "Notify.Success",
                "Cart Added Successfully"
              );
            } else {
              this.notify.showWarning(
                "Notify.Warning",
                "Cart is already exists."
              );
            }
            this.getAllCartsName();
          } else {
            this.notify.showWarning("Notify.Warning", res.Message);
          }
        });
    }
  }

  GetAllClients() {
  
    this.productService.GetAllClientsByRegister().subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.Clients = res.Data;
        let obj = { nM_Cliente: "All", iD_Entidade: "0" };
        this.Clients.splice(0, 0, obj);
      } else {
        this.Clients = [];
      }
    });
  }
  onClientChange(selectedClientId) {
    const selectedClient = this.Clients.find(client => client.iD_Entidade === selectedClientId);
    this.selectedClientName = selectedClient ? selectedClient.nM_Cliente : '';
    //  console.log( 'digital list client:', this.selectedClientName);
     this.sharedClientService.updateClient(this.selectedClientName);
     localStorage.setItem("iD_Entidade", selectedClientId.toString());
  }
  

  addUnits(event: any, index: any, products: any) {
    this.qBoxes = 0;
    this.qProduct = +event.target.value;
    var result = this.qProduct / products?.N_ItensPorCaixa;
    this.qBoxes = Math.ceil(result);
    if (this.tableItem$[index]) {
      this.tableItem$[index].Q_Caixas = this.qBoxes;
      if (this.boxUnitArr[index]) {
        this.boxUnitArr[index].boxes = this.qBoxes;
      } else {
        this.boxUnitArr[index] = {
          boxes: this.qBoxes,
          units: "",
        };
      }
    }
  }

  addBoxes(event: any, index: any, products: any) {
    // debugger
    this.qProduct = 0;
    this.qBoxes = +event.target.value;
    this.ShoppingCart.Q_Caixas = this.qBoxes;
    this.qProduct = this.ShoppingCart.Q_Caixas * products?.N_ItensPorCaixa;
    if (this.tableItem$[index]) {
      // this.tableItem$[index].N_ItensPorCaixa = this.qProduct
      if (this.boxUnitArr[index]) {
        this.boxUnitArr[index].units = this.qProduct;
      } else {
        this.boxUnitArr[index] = {
          units: this.qProduct,
          boxes: "",
        };
      }
    }
  }

  AddCart() {
    if (this.IsAddCart == false) {
      this.IsAddCart = true;
    } else {
      this.IsAddCart = true;
    }
  }

  activeDeactiveProd(Id: any) {
    this.spinner.show();
    this.productService.getChangeStatus(Id).subscribe(
      (res: any) => {
        if (res.ResponseCode == 200) {
          this.spinner.hide();
          this.notify.showSuccess("Notify.Success", res.Message);
          var item = this.tableItem$.find((i) => i.ID_Produto == Id);
          item.status = res.Data;
        } else {
          this.spinner.hide();
          this.notify.showSuccess("Notify.Warning", res.Message);
        }
      },
      (error) => {
        this.spinner.hide();
        this.notify.showError("Error", error.error.message);
      }
    );
  }

  onDiscard() {
    this.IsAddCart = false;
    this.newCartString = null;
    this.shoppingCartName = this.Carts[0];
    window.location.reload();
  }

  getFactoryDevison(id: any) {
    this.productService.getFactoryDivision(id).subscribe(
      (res: any) => {
        this.factorDevison = res;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  showFactoryDevision() {
    this.showFactorDevison = true;
  }

  hideFactoryDevision() {
    this.showFactorDevison = false;
  }

  onProdRowsChange() {
    if (this.prodRows == 3) {
      this.divSize = "col-xl-4";
    } else if (this.prodRows == 4) {
      this.divSize = "col-xl-3";
    } else if (this.prodRows == 2) {
      this.divSize = "col-xl-6";
    } else if (this.prodRows == 6) {
      this.divSize = "col-xl-2";
    }
    //this.changref.detectChanges();
  }

  removeItemFromCart(Id: any) {
    if (Id != null) {
      this.productService.RemoveCartById(Id).subscribe((res: any) => {
        if (res.ResponseCode == 200) {
          this.notify.showSuccess(
            "Notify.Success",
            "ProdSuccess.CartItemRemoved"
          );
          if (
            this.shoppingCartName === "" ||
            this.shoppingCartName == null ||
            this.shoppingCartName === undefined
          ) {
            this.getAllCartsName();
            this.shoppingCartName = this.Carts[0];
          }
          this.onGetShoppingCartItems(this.shoppingCartName);
        } else {
          this.notify.showWarning("Notify.Warning", "Item Not Deleted");
        }
      });
    }
  }

  removeCart(CartName: any) {
    // Check if CartName is null
    if (CartName == null) {
      this.notify.showWarning(
        "Notify.Warning",
        "Cart is null and cannot be deleted"
      );
      return;
    }

    Swal.fire({
      title: this.translate.instant("Notify.RemoveCart"),
      text: `${this.translate.instant(
        "Notify.Are_you_sure_want_to_remove_cart"
      )} ${CartName}?`,
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      cancelButtonColor: "red",
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.productService.DeleteCart(CartName).subscribe((res: any) => {
          if (res.ResponseCode == 200) {
            // debugger
            // this.ngOnInit();
            var selectedCart = this.Carts.findIndex((cart) => cart == CartName);
            if (selectedCart != -1) {
              this.Carts.splice(selectedCart, 1);
              if (this.Carts.length > 0) {
                this.shoppingCartName = this.Carts[0];
              }
            }
            Swal.fire({
              title: this.translate.instant("Notify.Cart_Removed"),
              icon: "success",
              text: this.translate.instant("Notify.Cart_successfully_removed"),
              timer: 2000,
              showConfirmButton: false,
            });
          } else {
            this.notify.showWarning("Notify.Warning", "Cart Not Deleted");
          }
        });
      }
    });
  }

  addSimilar(id: any) {
    this.Route.navigate([
      `products/digital/digital-add-product/addSimilar/${id}`,
    ]);
  }

  openModal(temp: any) {
    this.modalService.open(temp, {});
  }

  // closeModal() {
  //   this.modalService.dismissAll();
  // }

  deleteProduct(productId: any) {
    if (productId) {
      this.notify.showConfim(
        `Delete Product`,
        `Are you sure want to Delete Product?`,
        () => {
          this.productService.DeleteProduct(productId).subscribe(
            (res) => {
              this.spinner.show();
              if (res.ResponseCode == 200) {
                this.spinner.hide();
                this.notify.showSuccess("Success", res.Message);
                this.ngOnInit();
                window.location.reload();
              } else if (res.ResponseCode == 409) {
                this.spinner.hide();
                this.notify.showWarning("Warning", res.Message);
              } else {
                this.notify.showError("Error", res.Message);
                this.spinner.hide();
              }
            },
            (error) => {
              this.spinner.hide();
            }
          );
        }
      );
    }
  }

  //insert items in the shopping carts
  // insertIntoShoppingCart(shoppingCat: any) {
  //   this.productService
  //     .InsertShoopingCart(shoppingCat)
  //     .subscribe((res: any) => {
  //       if (res.ResponseCode == 200) {
  //         this.unitForm.reset();
  //         this.qBoxes = null;
  //         this.qProduct = null;
  //         this.ShoppingCart.Q_Produto = 0;
  //         this.ShoppingCart.Q_Caixas = 0;
  //         this.notify.showSuccess(
  //           "Notify.Success",
  //           "ShoppingCart.ItemAddedtoCartSuccessfully"
  //         );
  //         this.productService.CallPagination();
  //         this.onGetShoppingCartItems(this.shoppingCartName);
  //       } else {
  //         this.unitForm.reset();
  //         this.notify.showWarning("Notify.Warning", res.Message);
  //       }
  //     });
  // }

  insertIntoShoppingCart(shoppingCat: any) {
    this.productService
      .InsertShoopingCart(shoppingCat)
      .subscribe((res: any) => {
        if (res.ResponseCode == 200) {
          this.unitForm.reset();
          this.qBoxes = null;
          this.qProduct = null;
          this.ShoppingCart.Q_Produto = 0;
          this.ShoppingCart.Q_Caixas = 0;

          // Clear the inputs in the boxUnitArr array
          this.boxUnitArr.forEach((item) => {
            item.units = "";
            item.boxes = "";
          });

          this.notify.showSuccess(
            "Notify.Success",
            "ShoppingCart.ItemAddedtoCartSuccessfully"
          );
          this.productService.CallPagination();
          this.onGetShoppingCartItems(this.shoppingCartName);
        } else {
          this.unitForm.reset();
          this.notify.showWarning("Notify.Warning", res.Message);
        }
      });
  }

  gotProdutExistinOrders(id: any) {
    this.Route.navigate([`products/digital/product&Orders/${id}`]);
  }

  //not in use function
  classifyByUrlExtension() {
    for (let url of this.tableItem$) {
      this.extension = url.prodfiles[0]?.Prodfilepath.split(".")
        .pop()
        ?.toLowerCase();

      if (
        this.extension === "jpg" ||
        this.extension === "jpeg" ||
        this.extension === "png" ||
        this.extension === "gif"
      ) {
        this.Images.push(url);
      } else if (
        this.extension === "mp4" ||
        this.extension === "avi" ||
        this.extension === "mov"
      ) {
        this.videos.push(url);
      } else {
        this.videos = [];
        this.Images = [];
      }
    }
  }

  getExension(path) {
    if (path) {
      return path.split(".").pop()?.toLowerCase();
    } else {
      return null;
    }
  }

  isImageOrVideo(path, type): boolean {
    // debugger
    if (path) {
      var ext = this.getExension(path);
      var ImgExts = ["jpg", "jpeg", "png", "gif", "webp"];
      var VidExts = ["mp4", "mov", "avi"];
      if (type == 1) {
        if (ImgExts.includes(ext)) return true;
        else return false;
      } else if (type == 2) {
        if (VidExts.includes(ext)) return true;
        else return false;
      }
      return false;
    } else {
      return false;
    }
  }

  getImagePaths(item: any): string[] {
    const paths: string[] = [];
    if (item[2]?.hasOwnProperty("Prodfilepath")) {
      paths.push(item[2].Prodfilepath);
    }
    if (item[1]?.hasOwnProperty("CartImagePath")) {
      paths.push(item[1].CartImagePath);
    }
    if (item[0]?.hasOwnProperty("ProductImagePath")) {
      paths.push(item[0].ProductImagePath);
    }
    return paths;
  }

  onImageError(event: any) {
    event.target.src = "assets/images/NoImage.jpg";
  }

  onVideoError(event: any) {
    // Handle video error: replace the video with a fallback image or take another action
    event.target.poster = "assets/images/product-list/NoImage.jpg"; // Set a poster image as fallback
    event.target.src = ""; // Clear the video source to prevent further attempts to load
    event.target.controls = false; // Optionally remove controls if video fails
  }
  onSlide(event: NgbSlideEvent) {
    this.pauseAllVideos();
  }

  pauseAllVideos() {
    const videos = document.querySelectorAll("video");
    videos.forEach((video) => {
      video.pause();
    });
  }

  closeModal() {
    this.modalService.dismissAll();
    this.pauseAllVideos();
  }

  onStartDateChange(startDate: string) {
    this.minEndDate = startDate;
  }
}
