<div class="container-fluid">
    <form [formGroup]="ProductForm">
        <div class="row product-adding">
            <div class="col-lg-6 mb-3">
                <div class="card  h-100  ">
                    <div class="card-header">
                        <h5 *ngIf="btnType == 'addSimilar'">{{'Products.AddSimilar' | translate}}</h5>
                        <h5 *ngIf="Product?.ID_Produto > 0 && btnType != 'addSimilar'">{{'Products.EditProductDetials' |
                            translate}}</h5>
                    </div>
                    <div class="card-body">
                        <div class="digital-add needs-validation">
                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4 mb-2"><label class="req">{{'ProductAdd.Code' |
                                        translate}}:</label></div>
                                <div class="col-xl-4 col-md-3 mb-2"><input type="text" formControlName="Codigo_Produto"
                                        class="form-control" id="validationCustom2"></div>

                                <div class="col-xl-2 col-md-4"><label>{{'Products.isExclusiveForAllClients' |
                                        translate}}:</label>
                                </div>
                                <div class="col-xl-3 col-md-4">
                                    <input type="checkbox" formControlName="IsExclusiveForAllClients"
                                        (change)="onCheckboxChange()" [(ngModel)]="isFormControlDisabled">
                                </div>
                                <div class="col-xl-2 col-md-2" *ngIf="isFormControlDisabled == false">
                                    <label class="req d-flex">{{ 'Products.ExclusiveForClients' | translate }}:</label>
                                </div>
                                <div class="col-xl-10 col-md-10 mt-2" *ngIf="isFormControlDisabled == false">
                                    <ng-select class="form-con
                                    trol custom" [multiple]="true" [items]="Clients"
                                        formControlName="Client_IdEntidade" bindValue="iD_Entidade"
                                        bindLabel="nM_Cliente">
                                    </ng-select>
                                </div>
                            </div>

                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.RefrenceProvider' |
                                        translate}}:</label></div>
                                <div class="col-xl-4 col-md-3"><input type="text" formControlName="R_fornecedor"
                                        class="form-control"></div>

                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.CodeBar' | translate}}:</label>
                                </div>
                                <div class="col-xl-4 col-md-3">
                                    <input type="text" formControlName="Codigo_Barras" 
                                       class="form-control">
                                </div>
                            </div>
                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label class="req">{{'ProductAdd.Value' |
                                        translate}}:</label></div>
                                <div class="col-xl-4 col-md-3"><input type="text" id="validationCustom2"
                                        formControlName="N_ValorUnitario" class="form-control"></div>

                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.isActive' | translate}}:</label>
                                </div>
                                <div class="col-xl-4 col-md-3">
                                    <input type="checkbox" formControlName="isActive">
                                </div>

                            </div>


                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.ProviderValue' |
                                        translate}}:</label></div>
                                <div class="col-xl-4 col-md-4"><input type="text" formControlName="N_ValorFornecedor"
                                        class="form-control"></div>
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.PresentationValue' |
                                        translate}}:</label></div>
                                <div class="col-xl-4 col-md-6"><input type="text" formControlName="N_ValorApresentacao"
                                        class="form-control"></div>
                            </div>
                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label class="req">{{'ProductAdd.Itemper/Box' |
                                        translate}}:</label>
                                </div>
                                <div class="col-xl-4 col-md-3"><input type="number" min="1"
                                        (keypress)="this.hs.onlyNumbers($event)" formControlName="N_ItensPorCaixa"
                                        class="form-control" id="validationCustom2"></div>

                                <div class="col-xl-2 col-md-4"><label class="req">{{'ProductAdd.CBM' |
                                        translate}}:</label></div>
                                <div class="col-xl-2 col-md-2"><input type="text" formControlName="N_AreaCaixa"
                                        class="form-control"  [value]="ProductForm.get('N_AreaCaixa').value | customNumberFormat"
                                          (input)="resetDimensions()"  (input)="validateDecimalInput($event, 'N_AreaCaixa')"></div>
                                <div class="col-xl-2 col-md-2"><button class="btn btn-primary" type="button"
                                        (click)="CBMcalculatoin()" >CBM</button>
                                </div>
                            </div>
                            <div class="form-group row mb-2">
                                <div class="col-xl-4 d-flex align-items-center col-md-4">
                                    <label>{{'ProductAdd.Width' | translate}}:</label>
                                    <input type="text" formControlName="N_Largura" class="form-control" min="0"  (input)="validateDecimalInput($event, 'N_Largura')" >
                                        <div *ngIf="ProductForm.get('N_Largura').touched && ProductForm.get('N_Largura').invalid" class="text-danger">
                                            <small *ngIf="ProductForm.get('N_Largura').errors?.min">Width cannot be negative</small>
                                        </div>
                                </div>
                                <div class="col-xl-4 d-flex align-items-center col-md-4"><label>{{'ProductAdd.Length' | translate}}:</label><input type="text" formControlName="N_Comprimento"
                                        class="form-control" min="0" id="validationCustom2" (input)="validateDecimalInput($event, 'N_Comprimento')">
                                        <div *ngIf="ProductForm.get('N_Comprimento').touched && ProductForm.get('N_Comprimento').invalid" class="text-danger">
                                            <small *ngIf="ProductForm.get('N_Comprimento').errors?.min">Length cannot be negative</small>
                                          </div></div>
                                <div class="col-xl-4 d-flex align-items-center col-md-4"><label>{{'ProductAdd.Height' | translate}}:</label><input type="text" formControlName="N_Altura"
                                        class="form-control" min="0"  (input)="validateDecimalInput($event, 'N_Altura')">
                                        <div *ngIf="ProductForm.get('N_Altura').touched && ProductForm.get('N_Altura').invalid" class="text-danger">
                                            <small *ngIf="ProductForm.get('N_Altura').errors?.min">Height cannot be negative</small>
                                          </div></div>
                            </div>
                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.Weight' | translate}}:</label></div>
                                <div class="col-xl-10 col-md-7"><input type="text" formControlName="Peso"
                                        class="form-control"></div>
                            </div>

                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.PesoPacking' | translate}}:</label>
                                </div>
                                <div class="col-xl-4 col-md-4"><input type="number" formControlName="pesoPacking"
                                        class="form-control" min="0"></div>
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.PESOINNERPACKING' |
                                        translate}}:</label></div>
                                <div class="col-xl-4 col-md-4"><input type="number" formControlName="pesoInnerPacking"
                                        class="form-control" min="0"></div>

                            </div>
                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.INNER' | translate}} :</label></div>
                                <div class="col-xl-4 col-md-4"><input type="number" formControlName="inner"
                                        class="form-control" min="0"></div>
                                <div class="col-xl-2 col-md-4"
                                    *ngIf="Product?.ID_Produto > 0 && btnType != 'addSimilar' "><label>Total Packing
                                        Weight:</label></div>
                                <div class="col-xl-4 col-md-7"
                                    *ngIf="Product?.ID_Produto > 0 && btnType != 'addSimilar' ">
                                    <input type="text" class="form-control" disabled formControlName="totalWeight">
                                </div>

                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.HasSample' | translate}}:</label>
                                </div>
                                <div class="col-xl-4 col-md-7"><input type="checkbox" formControlName="temAmostra">
                                </div>
                            </div>
                            <!-- <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-12"><label>{{'IsSuggestion' | translate}}:</label>
                                </div>
                                <div class="col-xl-4 col-md-3">
                                    <input type="checkbox" formControlName="IsSuggestionProduct">
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-6 mb-3">
                <div class="card  h-100 ">
                    <div class="card-header">
                        <h5> {{'ProductAdd.DetailPackageProduct' | translate}}</h5>
                    </div>
                    <div class="card-body">
                        <div class="digital-add needs-validation">
                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label class="req d-flex">{{'Common.Providers' |
                                        translate}}:</label></div>
                                <div class="col-xl-10 col-md-7">
                                    <ng-select class="custom placeholder-size" formControlName="Provider_ID_Entidade"
                                        placeholder="Select Provider" [items]="Provider | async" bindValue="iD_Entidade"
                                        bindLabel="nM_Cliente">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label class="req">{{'Common.Family' |
                                        translate}}:</label></div>
                                <div class="col-xl-10 col-md-7">
                                    <ng-select class="custom placeholder-size" formControlName="iD_Familias"
                                        placeholder="Select Family" multiple="true" [items]="Family"
                                        bindValue="iD_Familia" bindLabel="n_Familia">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4 "><label class="req">{{'Common.Materials' |
                                        translate}}:</label></div>
                                <div class="col-xl-10 col-md-7">
                                    <ng-select class="custom placeholder-size" formControlName="iD_Materials"
                                        placeholder="Select Material" multiple="true" [items]="Material"
                                        bindValue="iD_Material" bindLabel="n_Material">
                                    </ng-select>
                                </div>
                            </div>

                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label class="req">{{'ProductAdd.Name' |
                                        translate}}:</label></div>
                                <div class="col-xl-10 col-md-7">
                                    <div class="d-flex">
                                        <input type="text" class="form-control" [ngModelOptions]="{standalone: true}"
                                            [(ngModel)]="Namept"><i class="flag-icon flag-icon-pt ms-1"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label class="req">{{'ProductAdd.Name' |
                                        translate}}:</label></div>
                                <div class="col-xl-10 col-md-7">
                                    <div class="d-flex">
                                        <input type="text" class="form-control" [(ngModel)]="NameES"
                                            [ngModelOptions]="{standalone: true}"><i
                                            class="flag-icon flag-icon-es ms-1"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label class="req">{{'ProductAdd.Name' |
                                        translate}}:</label></div>
                                <div class="col-xl-10 col-md-7">
                                    <div class="d-flex">
                                        <input type="text" class="form-control" [(ngModel)]="NameEn"
                                            [ngModelOptions]="{standalone: true}"><i
                                            class="flag-icon flag-icon-gb ms-1"></i>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.Presentation' | translate}}:</label>
                                </div>
                                <div class="col-xl-10 col-md-7">
                                    <div class="d-flex"><input type="text" [(ngModel)]="Presentationpt"
                                            [ngModelOptions]="{standalone: true}" class="form-control">
                                        <i class="flag-icon flag-icon-pt ms-1"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.Presentation' | translate}}:</label>
                                </div>
                                <div class="col-xl-10 col-md-7">
                                    <div class="d-flex"><input type="text" [(ngModel)]="PresentationES"
                                            [ngModelOptions]="{standalone: true}" class="form-control">
                                        <i class="flag-icon flag-icon-es ms-1"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.Presentation' | translate}}:</label>
                                </div>
                                <div class="col-xl-10 col-md-7">
                                    <div class="d-flex">
                                        <input type="text" [(ngModel)]="PresentationEn"
                                            [ngModelOptions]="{standalone: true}" class="form-control">
                                        <i class="flag-icon flag-icon-gb ms-1"></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div class="col-xl-6 mb-3">

                <div class="card h-100  ">
                    <div class="card-header">
                        <h5>{{'ProductAdd.FileUpload' | translate}}</h5>
                    </div>
                    <div class="card-body">
                        <div class="digital-add needs-validation">
                            <div class="form-group row mb-2">

                                <div class="col-xl-2 col-md-4"> <label class="col-form-label pt-0">
                                        {{'ProductAdd.ProductUpload' | translate}}</label></div>
                                <div class="col-xl-10 col-md-8">

                                    <div class="card-body dropzone-custom p-0">
                                        <ngx-dropzone class="dropzone-border" (change)="onSelectPorductFile($event)">
                                            <ngx-dropzone-label>
                                                <div class="dz-message needsclick">
                                                    <i class="fa fa-cloud-upload"></i>
                                                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                                </div>
                                            </ngx-dropzone-label>
                                            <ngx-dropzone-preview *ngFor="let f of files" [removable]="true"
                                                (removed)="onRemove(f)">
                                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                                <!-- <ngx-dropzone-label>  <img *ngIf="f.type.includes('image')" [src]="f.preview" class="img-preview" alt="{{ f.name }}"></ngx-dropzone-label> -->
                                                <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview"
                                                    [file]="f" [removable]="true" (removed)="onRemove(f)">
                                                </ngx-dropzone-video-preview>
                                            </ngx-dropzone-preview>
                                        </ngx-dropzone>
                                    </div>
                                </div>

                                <!-- <div class="col-xl-10 col-md-8">
                                    <div class="card-body dropzone-custom p-0">
                                        <ngx-dropzone 
                                            class="dropzone-border" 
                                            (change)="onSelectPorductFile($event)">
                                            <ngx-dropzone-label>
                                                <div class="dz-message needsclick">
                                                    <i class="fa fa-cloud-upload"></i>
                                                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                                </div>
                                            </ngx-dropzone-label>
                                            <ngx-dropzone-video-preview 
                                            *ngFor="let f of files" 
                                            [file]="f"
                                            [removable]="true"
                                            (removed)="onRemove(f)">
                                            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                            </ngx-dropzone-video-preview>
                                        </ngx-dropzone>
                                    </div>
                                </div> -->


                                <!-- <div class="col-xl-10 col-md-8 mt-2">
                                    <div class="row">
                                        <div class="col-xl-4 col-md-6 col-lg-4"
                                            *ngFor="let Product of personalizedImage"
                                            style="border-color: black;">
                                            <span *ngIf="Product.Image_Type == 'Products'">
                                                <img 
                                                [src]="Product.ProductImagePath" 
                                                class="img-product-list">
                                                <i class="fa-solid fa-circle-xmark close-icon-product"
                                                    (click)="removeProdImg(Product.Id)"></i>

                                                <video 
                                                controlsList="nodownload"
                                                controls
                                                [src]="Product.ProductImagePath" 
                                                class="img-product-list"></video>
                                                <i class="fa-solid fa-circle-xmark close-icon-product"
                                                    (click)="removeProdImg(Product.Id)"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="col-xl-10 col-md-8 mt-2">
                                    <div class="row">
                                        <div *ngFor="let imgs of Images;let i = index"
                                            class="col-xl-4 col-md-6 col-lg-4" style="border-color: black;">
                                            <span>
                                                <img [src]="imgs?.Prodfilepath" class="img-product-list">
                                                <i class="fa-solid fa-circle-xmark close-icon-product"
                                                    (click)="removeProdImg(imgs.id)"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-10 col-md-8 mt-2">
                                    <div class="row">
                                        <div *ngFor="let vids of videos;let i = index"
                                            class="col-xl-4 col-md-6 col-lg-4" style="border-color: black;">
                                            <span>
                                                <video controls controlsList="nodownload" [src]="vids?.Prodfilepath"
                                                    class="img-product-list"></video>
                                                <i class="fa-solid fa-circle-xmark close-icon-product"
                                                    (click)="removeProdImg(vids.id)"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"> <label class="col-form-label pt-0">
                                        {{'ProductAdd.CardUpload' | translate}}</label></div>
                                <div class="col-xl-10 col-md-8">
                                    <div class="card-body dropzone-custom p-0">
                                        <ngx-dropzone class="dropzone-border" (change)="onSelectCardFile($event)">
                                            <ngx-dropzone-label>
                                                <div class="dz-message needsclick">
                                                    <i class="fa fa-cloud-upload"></i>
                                                    <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                                </div>
                                            </ngx-dropzone-label>
                                            <ngx-dropzone-preview *ngFor="let cf of cardfiles" [removable]="true"
                                                (removed)="onRemoveCardFiles(cf.id)">
                                                <!-- <ngx-dropzone-label>{{ cf.name }} ({{ cf.type }})</ngx-dropzone-label> -->
                                            </ngx-dropzone-preview>
                                        </ngx-dropzone>
                                    </div>
                                </div>

                                <div class="col-xl-10 col-md-8 mt-2">
                                    <div class="row">
                                        <div class="col-xl-4 col-md-6 col-lg-4" *ngFor="let imgs of CardImages">
                                            <span>
                                                <img [src]="imgs?.Cartfilepath" class="img-product-list">
                                                <i class="fa-solid fa-circle-xmark close-icon-product"
                                                    (click)="removeProdImg(imgs.id)"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-10 col-md-8 mt-2">
                                    <div class="row">
                                        <div class="col-xl-4 col-md-6 col-lg-4"
                                            *ngFor="let vids of Cardvideos;let i = index">
                                            <span>
                                                <video controls controlsList="nodownload" [src]="vids?.Cartfilepath"
                                                    class="img-product-list"></video>
                                                <i class="fa-solid fa-circle-xmark close-icon-product"
                                                    (click)="removeProdImg(vids.id)"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-xl-6 mb-3">
                <div class="card h-100  ">
                    <div class="card-header">
                        <h5> {{'ProductAdd.AdditonalInformation' | translate}}</h5> 
                    </div>
                    <div class="card-body">
                        <div class="digital-add needs-validation">
                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label> {{'ProductAdd.Observations' |
                                        translate}}:</label></div>
                                <div class="col-xl-10 col-md-7">
                                    <div class="d-flex">
                                        <textarea formControlName="Observacoes"
                                        class="form-control" ></textarea>
                                        <i class="flag-icon  ms-1"></i>
                                    </div>
                                    </div>
                            </div>


                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.NotespClient' | translate}}:</label>
                                </div>
                                <div class="col-xl-10 col-md-7">
                                    <div class="d-flex">
                                        <textarea type="text" [(ngModel)]="NotePt" [ngModelOptions]="{standalone: true}"
                                            class="form-control"></textarea>
                                        <i class="flag-icon flag-icon-pt ms-1"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.NotespClient' | translate}}:</label>
                                </div>
                                <div class="col-xl-10 col-md-7">
                                    <div class="d-flex">
                                        <textarea type="text" [(ngModel)]="NoteES" [ngModelOptions]="{standalone: true}"
                                            class="form-control"></textarea>
                                        <i class="flag-icon flag-icon-es ms-1"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.NotespClient' | translate}}:</label>
                                </div>
                                <div class="col-xl-10 col-md-7">
                                    <div class="d-flex">
                                        <textarea type="text" [(ngModel)]="NoteEn" [ngModelOptions]="{standalone: true}"
                                            class="form-control"></textarea>
                                        <i class="flag-icon flag-icon-gb ms-1"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.TechnicalInfo' |
                                        translate}}:</label>
                                </div>
                                <div class="col-xl-10 col-md-7">
                                    <div class="d-flex">
                                        <textarea type="text" [(ngModel)]="TechnicalInfoEs"
                                            [ngModelOptions]="{standalone: true}" class="form-control"></textarea>
                                        <i class="flag-icon flag-icon-pt ms-1"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.TechnicalInfo' |
                                        translate}}:</label>
                                </div>
                                <div class="col-xl-10 col-md-7">
                                    <div class="d-flex">
                                        <textarea type="text" [(ngModel)]="TechnicalInfoPt"
                                            [ngModelOptions]="{standalone: true}" class="form-control"></textarea>
                                        <i class="flag-icon flag-icon-es ms-1"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mb-2">
                                <div class="col-xl-2 col-md-4"><label>{{'ProductAdd.TechnicalInfo' |
                                        translate}}:</label>
                                </div>
                                <div class="col-xl-10 col-md-7">
                                    <div class="d-flex">
                                        <textarea type="text" [(ngModel)]="TechnicalInfoEn"
                                            [ngModelOptions]="{standalone: true}" class="form-control"></textarea>
                                        <i class="flag-icon flag-icon-gb ms-1"></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-12 mb-3">
                <div class="product-buttons text-end mt-3">
                    <button type="button" class="btn btn-primary" (click)="onSubmit('Add')"
                        *ngIf="Product?.ID_Produto <=0 && btnType != 'addSimilar'" [disabled]="isDisable">{{'Common.Add'
                        | translate}}</button>
                    <button type="button" class="btn btn-primary" (click)="onSubmit('Update')"
                        *ngIf="Product?.ID_Produto > 0 && btnType != 'addSimilar'"
                        [disabled]="isDisable">{{'Common.Update' |
                        translate}}</button>
                    <button type="button" class="btn btn-primary" (click)="onSubmit('Save')"
                        *ngIf="btnType === 'addSimilar'" [disabled]="isDisable">{{'Common.Save' | translate}}</button>
                    <!-- <button type="button" class="btn btn-primary" (click)="onSubmit('SaveAndOpen')"
                        *ngIf="btnType === 'addSimilar'" [disabled]="isDisable">{{'ProductAdd.SaveandOpenNew' |
                        translate}}</button> -->
                    <button type="button" class="btn btn-primary" (click)="onSubmit('SaveAndAdd')"
                        *ngIf="btnType === 'addSimilar'" [disabled]="isDisable">{{'ProductAdd.SaveandAddSimilar' |
                        translate}}</button>
                    <button type="button" (click)="onCancel()" class="btn btn-light">{{'Common.Cancel' |
                        translate}}</button>
                </div>
            </div>

        </div>
    </form>
</div>