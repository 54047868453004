<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Product List</h5>
        </div>
        <div class="card-body">
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let product of products">
              <input
                type="checkbox"
                [(ngModel)]="product.isApproved"
                (change)="onApprove(product)"
                class="form-check-input me-2"
              />
              {{ product.name }}
              <span *ngIf="product.isApproved" class="text-success ms-2"> - Product is approved</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

