<div class="container-fluid">
    <div class="row">
        <div class="accordion mb-4 border-0" id="accordionExample">
            <div class="accordion-item border-0">
                <h2 class="accordion-header" id="headingOne" collapse="false">
                    <!-- <button (click)="toggle()" class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        {{'ProductManagement.actions'| translate}}
                    </button> -->
                </h2>
                <div [ngbCollapse]="" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <div class="row justify-content-between">
                            <div class="row justify-content-between">
                                <div class="col-md-4 col-lg-4 mb-2">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <Label>{{'Products.ProductName' | translate}}</Label>
                                            <input type="text" placeholder="Enter Product name" class="form-control"
                                                [(ngModel)]="pageConfig.filters.ProductName">
                                            <!-- <Label>{{'User.Cliente' | translate}} </Label>
                                        <ng-select class=" form-control custom" placeholder="Select Client..."
                                            [(ngModel)]="pageConfig.filters.ID_Entidade" [items]="clients" [clearable]="false"
                                            bindValue="iD_Entidade" bindLabel="nM_Cliente">
                                        </ng-select> -->
                                        </div>

                                        <div class="col-md-6">
                                            <Label>{{'Products.ProductCode'| translate}}</Label>
                                            <input type="text" placeholder="Enter Product Code" class="form-control"
                                                [(ngModel)]="pageConfig.filters.ProductNameOrCode">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-6 mb-2">
                                    <div>
                                        <label for="" class=" d-block">{{'ProductManagement.MakeAllAs'|
                                            translate}}</label>
                                        <div class="form-check form-check-inline p-0">
                                            <button class="btn btn-danger text-capitalize mx-2" *ngIf="activeShow"
                                                (click)="ActiveSelectedProducts(this.status.active)">{{"ProductManagement.active" | translate}}</button>
                                            <button class="btn btn-danger text-capitalize mx-2" *ngIf="deleteShow"
                                                (click)="ActiveSelectedProducts(this.status.delete)">{{"ProductManagement.delete" | translate}}</button>
                                            <button class="btn btn-danger text-capitalize" *ngIf="deactivShow"
                                                (click)="ActiveSelectedProducts(this.status.deactive)">{{"ProductManagement.deactive" | translate}}</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row justify-content-between">
                                <div class="col-md-5 col-lg-5 mb-2">
                                    <label for="">{{'ProductManagement.ProductBy' | translate}}</label>
                                    <div class="card mb-0 border-0">
                                        <div class="card-body" style="padding:4px;">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                    id="inlineRadio" checked (change)="statusChange('IsAll')"
                                                    #allradio>
                                                <label class="form-check-label" for="inlineRadio">All</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                    id="inlineRadio1" (change)="statusChange(1)" #activeRadio>
                                                <label class="form-check-label"
                                                    for="inlineRadio1">{{'ProductManagement.active' |
                                                    translate}}</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                    id="inlineRadio2" (change)="statusChange(2)" #deactiveRadio>
                                                <label class="form-check-label"
                                                    for="inlineRadio2">{{'ProductManagement.deactive' |
                                                    translate}}</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                                    id="inlineRadio3" (change)="statusChange(3)" #deletedRadio>
                                                <label class="form-check-label"
                                                    for="inlineRadio3">{{'ProductManagement.delete' |
                                                    translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-6 mb-2">

                                    <div class="row">
                                        <Label>{{"ProductAdd.SelectClient" | translate}}</Label>
                                        <div class="col-md-6 mb-3">
                                            <!-- <ng-select class="form-control custom"
                                                        placeholder="Select Client..." [items]="clients"
                                                        [clearable]="false" bindValue="iD_Entidade"
                                                        bindLabel="nM_Cliente" [multiple]="true"
                                                        [(ngModel)]="SelectedProductSuggestedClients">
                                                        <ng-option (click)="selectAllClients()">Select All
                                                        </ng-option>
                                                    </ng-select> -->
                                            <!-- 
                                            <ng-select class="form-control custom  " placeholder="Select Client..."
                                                [items]="clients" [clearable]="false" bindValue="iD_Entidade"
                                                bindLabel="nM_Cliente" [multiple]="true"
                                                [(ngModel)]="SelectedProductSuggestedClients" groupBy="selectedAllGroup"
                                                [selectableGroup]="true"
                                                (ngModelChange)="SelectClientDropDownChange($event)">

                                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                                                    let-index="index">
                                                    <input id="item-{{index}}" type="checkbox"
                                                        [ngModel]="item$.selected" /> Select All
                                                </ng-template>
                                            </ng-select> -->
                                            <!-- <ng-select class="form-control custom" placeholder="Select Client..."
                                                [items]="clients" [clearable]="false" bindValue="iD_Entidade"
                                                bindLabel="nM_Cliente" [multiple]="true"
                                                [(ngModel)]="SelectedProductSuggestedClients" groupBy="selectedAllGroup"
                                                [selectableGroup]="true"
                                                (ngModelChange)="SelectClientDropDownChange($event)">
                                                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$"
                                                    let-index="index">
                                                    <div (click)="SelectAll()">Select All</div>
                                                </ng-template>
                                            </ng-select> -->
                                            <ng-select class="form-control custom" placeholder="Select Client..."
                                                [clearable]="false" bindValue="iD_Entidade" bindLabel="nM_Cliente"
                                                [multiple]="true" [(ngModel)]="SelectedProductSuggestedClients"
                                                groupBy="group" [selectableGroup]="true"
                                                (ngModelChange)="SelectClientDropDownChange($event)">
                                                <ng-option [value]="selectAllOption">Select All</ng-option>
                                                <ng-option *ngFor="let client of clients" [value]="client">
                                                    {{ client.nM_Cliente }}
                                                </ng-option>
                                            </ng-select>
                                        </div>
                                        <div class="col-md-6">
                                            <button type="submit" class="btn btn-primary"
                                                (click)="makeAllSelectedSuggested()">{{'ProductManagement.MakeSuggestion'|
                                                translate}}</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <button class="btn btn-success me-2" (click)="filterBy() " [disabled]="!isSearchEnabled()">
                                        {{"OrdersList.Search" | translate}}
                                    </button>

                                    <button type="button" class="btn btn-danger btn-xs" (click)="clearFilters()">
                                        {{'Common.Clear' | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12">
        <div>
            <div class="m-4">
                <div class="row">
                    <!-- <as-split direction="horizontal"> -->
                    <!-- <as-split-area size="50"> -->
                    <!-- <h3 class="text-center">{{'ProductManagement.AllProducts'|translate}}</h3> -->

                    <div class="card card-hover me-3">
                        <div class="card-body p-3 product-box">
                            <div class="table-responsive mt-3">
                                <!-- <input type="checkbox" (change)="selectAllFirstArray($event.target.checked)"> Select All -->
                                <table class="table">
                                    <thead>
                                        <!-- <th>#</th> -->
                                        <th>Image</th>
                                        <th>{{'Products.ProductName' | translate}}</th>
                                        <th>{{'Products.Price' | translate}}</th>
                                        <th>Ref</th>
                                        <th>{{'Common.Action' | translate}}</th>
                                    </thead>
                                    <tbody>

                                        <tr *ngFor="let product of tableItem$;let i = index;">
                                            <!-- <td>{{i+1}}</td> a  -->
                                            <td>
                                                <!-- <a href="javascript:void(0)"> -->
                                                <img *ngIf="product?.prodfiles != null"
                                                    [src]="product.prodfiles[0]?.Prodfilepath"
                                                    style="height: 80px !important; width: 80px !important;"
                                                    class="img-fluid blur-up lazyload bg-img product-list-img"
                                                    onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />
                                                <!-- </a> -->
                                            </td>
                                            <td>{{ product.ProductName ? product.ProductName : '--'}}</td>
                                            <td>{{ product.Price | number : '1.2-2'}}</td>
                                            <td>{{ product.Codigo_Produto}}</td>
                                            <td>
                                                <input type="checkbox" [checked]="!product"
                                                    (change)="onSelectingSigleRecords($event,product)">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p class="text-center text-danger "  *ngIf="!loading && tableItem$.length == 0">
                                    {{'SuggestedProducts.recordNotFound' | translate}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <ngb-pagination [collectionSize]="(total$)" [(page)]="page" [pageSize]="pageSize" [maxSize]="10"
                        [rotate]="true" (pageChange)="getPage($event)">
                    </ngb-pagination>

                    <!-- </as-split-area> -->

                    <!-- <as-split-area size="50">
                            <h3 class="text-center">{{'ProductManagement.SelectedProducts'| translate}}</h3>
                            <div class="card card-hover me-3">
                                <div class="card-body p-3 product-box">
                                    <div class="table-responsive mt-3">
                                        <table class="table">
                                            <thead>
                                                <th>Image</th>
                                                <th>Des</th>
                                                <th>Price</th>
                                                <th>Ref</th>
                                                <th>Action</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let product of productSelected; let i=index">
                                                    <td>
                                                        <a href="javascript:void(0)"
                                                            *ngIf="product.ProdfilesPath != null">
                                                            <img [src]="product.ProdfilesPath[0]"
                                                                style="height: 80px !important; width: 50px !important;"
                                                                class="img-fluid blur-up lazyload bg-img product-list-img"
                                                                onerror="this.onerror=null; this.src=`../assets/images/product-list/NoImage.jpg`" />
                                                        </a>
                                                    </td>
                                                    <td>{{ product.C_Produto}}</td>
                                                    <td>{{ product.Price | number : '1.2-2'}}</td>
                                                    <td>{{ product.Codigo_Produto}}</td>
                                                    <td>
                                                        <i class="text-danger fw-bold" style="cursor: pointer;"
                                                            (click)="DeleteSelectedProduct(i)">X</i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <ngb-pagination 
                                [collectionSize]="(totalSelected)" 
                                [(page)]="pageSelected"
                                [pageSize]="selectedPageSize" 
                                [maxSize]="10" 
                                [rotate]="true"
                                (pageChange)="getSelectedPage($event)">
                            </ngb-pagination>

                        </as-split-area> -->
                    <!-- </as-split> -->

                </div>
            </div>
        </div>
    </div>
</div>