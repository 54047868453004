import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
import { TranslateService } from '@ngx-translate/core';
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	isVisible?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window, private translateService: TranslateService) {
		this.onResize();

		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	// method(key: string) {
	// 	debugger
	// 	console.log(key);
	// 	var nRes = "";
	// 	var lres = "";

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/default', title: "Sidebar.Dashboard", icon: 'home', type: 'link', badgeType: 'primary', active: false, isVisible: true
		},

		{
			title: "Sidebar.Products", icon: 'box', type: 'sub', active: false, isVisible: true, children: [
				// { path: '/products/digital/digital-category', title: 'Category', type: 'link' },
				// { path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
				{ path: '/products/digital/digital-product-list', title: "Sidebar.ProductList", type: 'link' },
				{ path: '/products/digital/digital-add-product/add', title: "Sidebar.AddProduct", type: 'link' },
				{ path: '/products/digital/product-manage', title: "ProductManagement.ProductManagement", type: 'link' },
				{ path: '/products/digital/suggest-product', title: "Dashboard.SuggestedProducts", type: 'link' },
				{ path: '/products/digital/productPersonalize', title: "ProductPersonalization.ProductPersonalization", type: 'link' },
				// { path: '/products/digital/app-to-approve', title: "ToApprove", type: 'link' },
			]
		},
		{
			title: "Sidebar.ShoppingCart", icon: 'align-left', type: 'sub', active: false, isVisible: true, children: [
				{ path: '/shopping-cart/list-cart', title: "ShoppingCart.Cart", type: 'link' },
				// { path: '/currency/list-currency', title: 'Currency', type: 'link' },
				// { path: '/material/list-material', title: 'Material', type: 'link' },
			]
		},
		{
			title: "Sidebar.Orders", icon: 'user-plus', type: 'sub', active: false, isVisible: true, children: [
				{ path: '/orders/list-orders', title: "Sidebar.OrdersList", type: 'link' },
				{ path: '/orders/list-order-rest', title: "Sidebar.OrderRests", type: 'link' },

			]
		},

		{
			title: "Sidebar.Containers", icon: 'box', type: 'sub', active: false, isVisible: true, children: [
				{ path: '/containers/list-container', title: "Sidebar.ContainersList", type: 'link' },
				// { path: '/users/create-user', title: 'Create User', type: 'link' },
			]
		},
		{
			title: "Sidebar.Customers", icon: 'user-plus', type: 'sub', active: false, isVisible: true, children: [
				{ path: '/customers/list-customer', title: "Sidebar.CustomersList", type: 'link' },
				// { path: '/users/create-user', title: 'Create User', type: 'link' },
			]
		},

		{
			title: "Sidebar.SystemTables", icon: 'align-left', type: 'sub', active: false, isVisible: true, children: [
				{ path: '/families/list-families', title: "Sidebar.Families", type: 'link' },
				{ path: '/currency/list-currency', title: "Sidebar.Currency", type: 'link' },
				{ path: '/material/list-material', title: "Sidebar.Material", type: 'link' },
			]
		},

		{
			title: "Sidebar.AdditionalCost", icon: 'align-left', type: 'sub', active: false, isVisible: true, children: [
				{ path: '/Additional-Cost/list-cost', title: "Sidebar.Costs", type: 'link' },
				// { path: '/currency/list-currency', title: 'Currency', type: 'link' },
				// { path: '/material/list-material', title: 'Material', type: 'link' },
			]
		},
		// {
		// 	title: 'Families', icon: 'box', type: 'sub', active: false, children: [
		// 		{ path: '/families/list-families', title: 'Families List', type: 'link' },
		// 		{ path: '/families/create-families', title: 'Add Families', type: 'link' },
		// 	]
		// },

		// {
		// 	title: 'Sales', icon: 'dollar-sign', type: 'sub', active: false, children: [
		// 		{ path: '/sales/orders', title: 'Orders', type: 'link' },
		// 		{ path: '/sales/transactions', title: 'Transactions', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Coupons', icon: 'tag', type: 'sub', active: false, children: [
		// 		{ path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
		// 		{ path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Pages', icon: 'clipboard', type: 'sub', active: false, children: [
		// 		{ path: '/pages/list-page', title: 'List Page', type: 'link' },
		// 		{ path: '/pages/create-page', title: 'Create Page', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Media', path: '/media', icon: 'camera', type: 'link', active: false
		// },
		// {
		// 	title: 'Menus', icon: 'align-left', type: 'sub', active: false, children: [
		// 		{ path: '/menus/list-menu', title: 'Menu Lists', type: 'link' },
		// 		{ path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
		// 	]
		// },

		{
			title: "Sidebar.Users", icon: 'user-plus', type: 'sub', active: false, isVisible: true, children: [
				{ path: '/users/list-user', title: "Sidebar.UserList", type: 'link' },
				{ path: '/users/users-loggins', title: "User.UserLoggins", type: 'link' },
				// { path: '/users/create-user', title: "Sidebar.CreateUser", type: 'link' },
			]
		},
		{
			title: "Sidebar.Providers", icon: 'users', type: 'sub', active: false, isVisible: true, children: [
				{ path: '/providers/list-provider', title: "Sidebar.ProviderList", type: 'link' },
			]
		},
		{
			title: "Sidebar.Statistics", icon: 'users', type: 'sub', active: false, isVisible: true, children: [
				{ path: '/Statistics/Statistics', title: "Sidebar.Statistics", type: 'link' }
			]
		},

		{
			title: "Sidebar.Setting", icon: 'settings', type: 'sub', isVisible: true, children: [
				{ path: '/settings/profile', title: "Sidebar.Profile", type: 'link' },
				{ path: '/users/user-settings', title: "UserSettings.UserSettings", type: 'link' },
				{ path: '/users/global-setting', title: "Global Setting", type: 'link' },
			]
		},

	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
