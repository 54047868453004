import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SharedClientServiceService {
  
  private clientSource = new BehaviorSubject<string>('Juan Office Admin');
  currentClient$ = this.clientSource.asObservable();

  updateClient(clientName: string) {
    this.clientSource.next(clientName);
  }

  constructor() { }
}
