import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HelperService } from 'src/app/shared/service/helper.service';
import { NotifyService } from 'src/app/shared/service/notify.service';
import { ProductService } from 'src/app/shared/service/product.service';
import { ProviderService } from 'src/app/shared/service/provider.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-digital-add',
  templateUrl: './digital-add.component.html',
  styleUrls: ['./digital-add.component.scss']
})


export class DigitalAddComponent implements OnInit {
  public Editor = ClassicEditor;

  ProductForm: FormGroup | any;

  Family: any;
  Material: any;
  Provider: any;
  Clients: any;

  NameEn: any;
  NameES: any;
  Namept: any;

  NoteEn: any;
  NoteES: any;
  NotePt: any;
  TechnicalInfoEn: any;
  TechnicalInfoEs: any;
  TechnicalInfoPt: any;
  Product: any;
  Familai: any;
  Materialia: any;

  btnType: any;
  IdProducto: any;

  CardImageSrc: any;
  PresentationEn: any;
  PresentationES: any;
  Presentationpt: any;
  prodPersonalizationId: any;
  HistorProdPersonalization: any;
  CodigoBarras : any ;

  isDisable: boolean = false;
  isFormControlDisabled: boolean = true;

  files: File[] = [];
  ImageUrl: any[] = [];
  ImageSrc: any[] = [];
  cardfiles: File[] = [];
  ImageIndex: any[] = [];
  CardImageUrl: any[] = [];
  CardImageIndex: any[] = [];
  ProductLanguage: any[] = [];
  ClientExclusives: any[] = [];
  personalizedImage: any[] = []; //personalized images array
  prodfilesPath: any[] = [];

  Images: any[] = [];
  videos: any[] = [];
  CardImages: any[] = [];
  Cardvideos: any[] = [];
  ProductImgsSrc: any[] = [];
  CardFilesPath: any[] = [];

  totalPackagingWeight: any = 0;

  public config: DropzoneConfigInterface = {
    acceptedFiles: 'video/*',
  }

  validationMapping: any = {
    N_ValorUnitario: { required: "ProdValWarnings.N_ValorUnitarioReq", pattern: "Value must be a number" },
    N_Comprimento: { required: "ProdValWarnings.N_ComprimentoReq", pattern: "Length must be a number" },
    N_ItensPorCaixa: { required: "ProdValWarnings.N_ItensPorCaixaReq", pattern: "Item per/Box must be a number" },
    // Codigo_Produto: { required: "ProdValWarnings.Codigo_Produto", pattern: "Code must be a number" },
    Codigo_Produto: { required: "ProdValWarnings.Codigo_Produto" },
    Provider_ID_Entidade: { required: 'Please select a provider' },
    iD_Familias: { required: "ProdValWarnings.iD_Familias" },
    iD_Materials: { required: "ProdValWarnings.iD_Materials" },
    N_AreaCaixa: { required: 'CBM is Required', pattern: "CBM must be a number", },
    ClientId_Entidade: { required: 'Please exclusiving the product for some client' },

  };


  constructor(
    private product: ProductService,
    private provider: ProviderService,
    private notify: NotifyService,
    private aroute: ActivatedRoute,
    public hs: HelperService,
    private Route: Router,
    private spinner: NgxSpinnerService
  ) {
    this.onGetParams();
  }


  onGetParams() {
    this.aroute.params.subscribe(params => {
      if (params['id']) {
        this.IdProducto = params['id'];
      }
      if (params['btnType']) {
        this.btnType = params['btnType'];
      }
    });
  }

  onSelect(event) {
    // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  onRemoveCardFiles(event) {
    this.cardfiles.splice(this.files.indexOf(event), 1);
  }

  ngOnInit() {
    this.onFormInit();
    this.GetProductById();
    this.GetAllFamilies();
    this.GetAllMaterial();
    this.GetAllClients();
    this.Provider = this.provider.providers$;
  }

  onFormInit() {
    this.ProductForm = new FormGroup({
      // Codigo_Produto: new FormControl('', [Validators.required, Validators.pattern(/^[\d\/.-]+$/)]),
      Codigo_Produto: new FormControl('', [Validators.required]),
      N_ValorUnitario: new FormControl('', [Validators.required, Validators.pattern(/^[\d\/.-]+$/)]),
      N_ItensPorCaixa: new FormControl('', [Validators.required, Validators.pattern(/^\d+$/)]),
      N_AreaCaixa: new FormControl(null, [Validators.required,Validators.pattern(/^[\d\/.,-]+$/)]),
      // N_AreaCaixa: new FormControl(null, [Validators.required, Validators.pattern(/^[\d\/.-]+$/)]),
      iD_Familias: new FormControl([], [Validators.required]),
      iD_Materials: new FormControl([], [Validators.required]),
      N_Comprimento: new FormControl(null, [Validators.pattern(/^\d+$/)]),
      ID_Produto: new FormControl(0),
      Provider_ID_Entidade: new FormControl(null, [Validators.required]), //provider id_entidade
      ID_TipoEmbalagem: new FormControl(''),
      C_Produto: new FormControl(''),
      N_Largura: new FormControl('', [Validators.required, Validators.min(0)]),
      N_Altura: new FormControl(),
      Codigo_Barras: new FormControl(''),
      R_fornecedor: new FormControl(0),
      Foto_Exp: new FormControl(),
      Pais_Origem: new FormControl(),
      Unidade_Medida: new FormControl(),
      Peso: new FormControl(),
      Venda_Minima: new FormControl(),
      Observacoes: new FormControl(),
      Stock_Anterior: new FormControl(),
      idHistorico: new FormControl(),
      dtIntro: new FormControl(),
      status: new FormControl(),
      erro: new FormControl(),
      eliminar: new FormControl(),
      marcarPDesactivar: new FormControl(),
      N_ValorFornecedor: new FormControl(),
      N_ValorApresentacao: new FormControl(''),
      temAmostra: new FormControl(false),
      productLanguages: new FormControl(),
      isActive: new FormControl(true),
      pesoPacking: new FormControl(),
      pesoInnerPacking: new FormControl(),
      inner: new FormControl(),
      totalWeight: new FormControl(),
      Client_IdEntidade: new FormControl({ value: [], disabled: false }), //Exclusive client Id_Entidade
      IsExclusiveForAllClients: new FormControl(false), //checkbox control by default true

    });
  }
  CBMcalculatoin() {
    // debugger
    const width = this.ProductForm.get('N_Largura').value;
    const height = this.ProductForm.get('N_Comprimento').value;
    const length = this.ProductForm.get('N_Altura').value;
    // var cbm = width * height * length / 1000000
    // const cbm = Number((width * height * length / 1000000).toFixed(3));
    const cbm = parseFloat((width * height * length / 1000000).toFixed(3));
    this.ProductForm.get('N_AreaCaixa').setValue(cbm);
  }
  GetProductById() {
  
    if (this.IdProducto) {

      this.spinner.show();
      this.product.GetProductById(this.IdProducto).subscribe((res: any) => {
        // console.log('id --> ', res.Data);
        if (res.ResponseCode == 200) {
          
          this.Familai = res.Data.Familias;
          this.Materialia = res.Data.Materials;
          this.Product = res.Data;
          this.ClientExclusives = res.Data.ClientExclusives;
          this.personalizedImage = res.Data.personalizedImages;
          this.prodfilesPath = res.Data.prodfiles;
           this.CardFilesPath = res.Data.CardfilePaths;
          // this.totalPackagingWeight = (res.Data.N_ItensPorCaixa * res.Data.pesoPacking) + (res.Data.inner * res.Data.pesoInnerPacking);
          // console.log(this.totalPackagingWeight, " total weight");

          this.classifyByUrlExtension();
          this.classifyByUrlExtensionCardFiles();
          // this.prodPersonalizationId = res.Data?.productPersonalization?.Id;// need to check

          //Get the products images form personalizedImage array
          if (this.personalizedImage) {
            this.personalizedImage.forEach(element => {
              if (element.Image_Type == "Products") {
                this.Product.ProductImagePath = element.ProductImagePath;
                // this.ProductImgsSrc.push(element);
              }
            });
          }
          

          // this.classifyByUrlExtension2Personlized();

          //get the card images form the personalizedImage array 
          if (this.personalizedImage) {
            this.personalizedImage.forEach(element => {
              if (element.Image_Type == "Cards") {
                this.CardImageSrc = element.CardfilePaths;
              }
            });
          }
          if (res.Data.productLanguages !=null) {
            if (res.Data.productLanguages.length > 0) {
              res.Data.productLanguages.forEach(element => {
                if (element.codLingua == 'en') {
                  this.NameEn = element?.C_Produto;
                  this.PresentationEn = element?.apresentacao;
                  this.NoteEn = element?.N_pCliente;
                  this.TechnicalInfoEn = element?.Technical_Info;
                }
                if (element.codLingua == 'es') {
                  this.NameES = element?.C_Produto
                  this.PresentationES = element?.apresentacao
                  this.NoteES = element?.N_pCliente
                  this.TechnicalInfoEs = element?.Technical_Info;
                }
                if (element.codLingua == 'pt') {
                  this.Namept = element?.C_Produto
                  this.Presentationpt = element?.apresentacao
                  this.NotePt = element?.N_pCliente
                  this.TechnicalInfoPt = element?.Technical_Info;
                }
              });
            }
          }
          this.ProductForm.patchValue(res.Data);
          // this.ProductForm.get('totalWeight').setValue(this.totalPackagingWeight);

          //based on btnType we are passing the id or not
          if (this.btnType == 'addSimilar') {
            this.ProductForm.patchValue({ ID_Produto: 0 })
            this.ProductForm.patchValue({ Codigo_Produto: null });
          }
        
          // this.ProductForm.patchValue({ Codigo_Barras: '' });
         // Hunny Code to clear add similar product
         if (this.btnType == 'addSimilar') {
          this.ProductForm.patchValue({
            Codigo_Barras: null, 
            N_ValorUnitario: null,
            Foto_Exp: null,
            Client_IdEntidade: []  
          });
        }

        if (this.btnType == 'addSimilar') {
          this.Images = []; 
          this.videos=[];
          this.CardImages =[]; 
          this.Cardvideos=[];
        }
          
          
          
        }
        this.spinner.hide();
      });
    }
  }

  //Select Product image files
  onSelectPorductFile(event) {
  //   const allowedExtensions = [
  //     'png','jpeg','jpg', 'jfif','svg','heic',
  //     // Video formats
  //     'mp4', 'mov', 'avi','mkv', 'wmv',
  // ];
    this.files.push(...event.addedFiles);
    if (event.addedFiles && event.addedFiles[0]) {
      for (let i = 0; i < event.addedFiles.length; i++) {
        // const file = event.addedFiles[i];
        // const fileExtension = file.name.split('.').pop()?.toLowerCase();
        // if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
        //     this.notify.showError('Invalid file type. Please select an image or video file.');
        //     return;
        // }
        this.ImageIndex.push(event.addedFiles[i]);
        var reader = new FileReader();
        // reader.onload = (event: any) => {
        //   event.addedFiles[i].preview = event.target.result;
        // };
        reader.readAsDataURL(event.addedFiles[i]);
      }
    }
  }

  //Select Card image files
  onSelectCardFile(event) {
    this.cardfiles.push(...event.addedFiles);
    if (event.addedFiles && event.addedFiles[0]) {
      for (let i = 0; i < event.addedFiles.length; i++) {
        this.CardImageIndex.push(event.addedFiles[i]);
        var reader = new FileReader();
        reader.readAsDataURL(event.addedFiles[i]);
      }
    }
  }

  //pathc product languages
  onProductLanguagePatch() {
  
    this.ProductLanguage = [];
    this.ProductLanguage.push({
      iD_Produto: 0,
      c_Produto: this.NameEn,
      apresentacao: this.PresentationEn,
      n_pCliente: this.NoteEn,
      Technical_Info: this.TechnicalInfoEn,
      codLingua: 'en'
    });
    this.ProductLanguage.push({
      iD_Produto: 0,
      c_Produto: this.NameES,
      apresentacao: this.PresentationES,
      n_pCliente: this.NoteES,
      Technical_Info: this.TechnicalInfoEs,
      codLingua: 'es'
    });
    this.ProductLanguage.push({
      iD_Produto: 0,
      c_Produto: this.Namept,
      apresentacao: this.Presentationpt,
      n_pCliente: this.NotePt,
      Technical_Info: this.TechnicalInfoPt,
      codLingua: 'pt'
    });
    this.ProductForm.controls.productLanguages.patchValue(this.ProductLanguage);
  }


  onSubmit(btnType: any) {
  
    this.spinner.show();
    this.onProductLanguagePatch();
    if (this.ProductForm.valid) {
      const formData = new FormData();

      if (this.ImageIndex.length > 0) {
        this.ImageIndex.forEach(element => {
          formData.append('aFormProdFile', element);
        });
      }

      if (this.CardImageIndex.length > 0) {
        this.CardImageIndex.forEach(element => {
          formData.append('aFromCardFile', element);
        });
      }

      if (btnType == "Update") {
        // toDo: IdProducto need to Patch
        // this.ProductForm.value.productPersonalization.Id = this.prodPersonalizationId;
      }

      formData.append('Data', JSON.stringify(this.ProductForm.value));

      if (btnType === "Add") {
        this.onAddProduct(formData);
      }
      else if (btnType == "Update") {
        this.onUpdateProduct(formData);
      }
      else if (btnType == "Save") {
        this.onSaveProduct(formData);
      }
      else if (btnType == "SavewAndOpen") {
        this.onSaveAndOpenProduct(formData);
      }
      else if (btnType == "SaveAndAdd") {
        this.onSaveAndAddProducts(formData);
      }
      this.spinner.hide();
    }
    else {
      this.spinner.hide();
      this.isDisable = false;
      this.hs.GetErrorsFromFormGroup(this.ProductForm, this.validationMapping);
    }
  }

  //Add product Functions
  onAddProduct(formData) {
    if (this.NameES && this.NameEn && this.Namept) {
      this.product.CreateProduct(formData).subscribe((res: any) => {
        if (res.ResponseCode == 200) {
          this.isDisable = true;
          this.notify.showSuccess('Notify.Success', "ProdSuccess.ProdAdded");
          this.isDisable = false;
          this.Route.navigate(['/products/digital/digital-product-list']);
        } else if (res.ResponseCode == 409) {
          this.spinner.hide();
          this.notify.showWarning("Product Code Already exisited", '409');
        }
        else {
          this.isDisable = false;
          this.notify.showWarning('Notify.Warning', res.Message);
          this.spinner.hide();
        }
      }, (err) => {
        this.spinner.hide();
        return err;
      });
    }
    else {
      if (this.NameES == null) {
        this.notify.showWarning("Notify.Warning", "Name is required");
      }
      else if (this.NameEn == null) {
        this.notify.showWarning("Notify.Warning", "Name is required");
      }
      else if (this.Namept == null) {
        this.notify.showWarning("Notify.Warning", "Name is required");
      }
    }
  }

  //Update Product functions
  onUpdateProduct(formData) {
    this.spinner.show();
    this.product.updateProduct(formData).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.spinner.hide();
        this.isDisable = true;
        this.notify.showSuccess('Notify.Success', "ProdSuccess.ProdUpdate");
        this.isDisable = false;
        this.Route.navigate(['/products/digital/digital-product-list']);
      }
      else {
        this.isDisable = false;
        this.notify.showWarning('Notify.Warning', res.Message);
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      return err;
    });
  }

  //when btn type is of save
  onSaveProduct(formData) {
    this.product.CreateProduct(formData).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.notify.showSuccess('Notify.Success', "ProdSuccess.ProdAdded");
        this.isDisable = false;
        this.Route.navigate(['/products/digital/digital-product-list']);
      }
      else if (res.ResponseCode == 409) {
        this.notify.showWarning("Product Code Already exisited", '409');
        this.spinner.hide();
      }
      else {
        this.isDisable = false;
        this.notify.showWarning('Notify.Warning', res.Message);
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      return err;
    });
  }

  //when btn typr is save and open 
  onSaveAndOpenProduct(formData) {
    this.product.CreateProduct(formData).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.notify.showSuccess('Notify.Success', "ProdSuccess.ProdAdded");
        this.isDisable = false;
        this.Route.navigate(['products/digital/digital-add-product/addSimilar']);
      }
      else {
        this.isDisable = false;
        this.notify.showWarning('Notify.Warning', res.Message);
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      return err;
    });
  }

  //when btn type is save and add
  onSaveAndAddProducts(formData) {
    this.product.CreateProduct(formData).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.notify.showSuccess('Notify.Success', "ProdSuccess.ProdAdded");
        this.isDisable = false;
        this.Route.navigate(['products/digital/digital-edit-product/addSimilar', res.Data]);
      }
      else {
        this.isDisable = false;
        this.notify.showWarning('Notify.Warning', res.Message);
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      return err;
    });
  }


  onCancel() {
    this.Route.navigate(['/products/digital/digital-product-list'])
  }

  GetAllFamilies() {
    this.product.GetAllFamilies().subscribe((data: any) => {
      this.Family = data.Data;
    });
  }

  GetAllMaterial() {
    this.product.GetAllMaterial().subscribe((data: any) => {
      this.Material = data.Data;
    });
  }

  GetAllClients() {
    this.product.GetAllClientsByRegister().subscribe((res: any) => {
      this.Clients = res.Data;
    });
  }

  removeProdImg(Id: any) {
  
    console.log(Id);
    this.notify.showConfim("Remove Image", "Are you sure want to remove Image", () => {
      Swal.fire({
        title: 'Removed!',
        text: 'Image Removed Successfully',
        icon: 'success'
      })
      if (Id) {
        this.product.RemoveProductImage(Id).subscribe((res: any) => {
          if (res.ResponseCode == 200) {
            this.notify.showSuccess('Notify.Success', "ProdSuccess.ImageRemoved");
             window.location.reload();
            // this.Route.navigate(['products/digital/digital-product-list'])
          }
          else {
            this.notify.showWarning('Notify.Warning', res.Message);
          }
        })
      }

    })
  }

  //Exclusive client checkbox funtion
  onCheckboxChange() {
    const idEntidadeControl = this.ProductForm.get('Client_IdEntidade');
    if (this.isFormControlDisabled) {
      idEntidadeControl.disable();
      idEntidadeControl.clearValidators();
    } else {
      idEntidadeControl.enable();
      idEntidadeControl.setValidators(Validators.required);
    }
  }

  classifyByUrlExtension() {
    for (let url of this.prodfilesPath) {
      const extension = url.Prodfilepath.split('.').pop()?.toLowerCase();
      // console.log(extension);
      if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
        this.Images.push(url);
      }
      else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {
        this.videos.push(url);
      }
      else {
        this.videos = [];
        this.Images = [];
      }
    }
  }

  classifyByUrlExtensionCardFiles() {
    for (let url of this.CardFilesPath) {
      const extension = url.Cartfilepath.split('.').pop()?.toLowerCase();
      if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
        this.CardImages.push(url);
      }
      else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {
        this.Cardvideos.push(url);
      }
      else {
        this.videos = [];
        this.Images = [];
      }
    }
  }

  //need to check, not use for now
  classifyByUrlExtension2Personlized() {
    for (let url of this.ProductImgsSrc) {
      // console.log(url);
      if (url.Image_Type == 'Products' && this.ProductImgsSrc != null) {
        var extension = url.ProductImagePath?.split('.')?.pop()?.toLowerCase();
      }
      console.log(extension);
      if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'gif') {
        this.Images.push(url);
      }
      else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {
        this.videos.push(url);
      }
      else {
        this.videos = [];
        this.Images = [];
      }
    }
  }

  resetDimensions(){
    this.ProductForm.get('N_Largura').setValue(0);
    this.ProductForm.get('N_Comprimento').setValue(0);
    this.ProductForm.get('N_Altura').setValue(0);
  
  }
  validateDecimalInput(event: any, controlName: string) {
    const value = event.target.value;
  
    // Allow optional leading numbers, or comma as decimal separator, with up to 3 decimal places
    const decimalPattern = /^(\d{0,3})(,\d{0,3})?$/;
  
    // Replace comma with a period for internal validation
    const normalizedValue = value.replace(/,/g, '.');
  
    if (!decimalPattern.test(value)) {
      // Allow only valid value with decimal format (comma as decimal)
      const validValue = normalizedValue.match(/^(\d{0,3})(\.\d{0,3})?/);
      // Replace back the period with comma and set the value
      this.ProductForm.get(controlName).setValue(validValue ? validValue[0].replace(/\./g, ',') : '');
    }
  }
  

}
