import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx-js-style';
import { GenericService } from './generic.service';
import { NotifyService } from './notify.service';
import { ShoppingCartService } from './shopping-cart.service';
import jsPDf from 'jspdf';
import autotable from 'jspdf-autotable';
import html2pdf from 'html2pdf.js';
import autoTable from 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(
    public genereicService: GenericService,
    public notify: NotifyService,
    public shoppingCart: ShoppingCartService
  ) { }

  fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  fileExtension = '.xlsx';
  date = new Date();


  public exportExcel(jsonData: any[], fileName: string, lheader: any[]) {


    const worksheet = XLSX.utils.json_to_sheet([[], [], [], [], [], []], { header: lheader });
    const image = {
      path: 'path/to/image.png',
      type: 'picture',
      position: {
        type: 'twoCellAnchor',
        from: { col: 0, colOff: 0, row: 0, rowOff: 0 },
        to: { col: 3, colOff: 0, row: 5, rowOff: 0 }
      }
    };

    for (let i = 0; i < jsonData.length; i++) {
      const row = jsonData[i];
      XLSX.utils.sheet_add_json(worksheet, [row], { skipHeader: true, origin: -1 });
    }

    const workbook = XLSX.utils.book_new();
    const headerStyle = {
      fill: {
        type: "pattern",
        patternType: "solid",
        fgColor: { rgb: "FFFF00" },
      },
      font: {
        bold: true,
      },
    };

    // Assign style to cells in first row
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let i = range.s.c; i <= range.e.c; i++) {
      const cellRef = XLSX.utils.encode_cell({ r: range.s.r, c: i });
      const cell = worksheet[cellRef];
      if (cell) {
        cell.s = headerStyle;
      } else {
       // console.log('no cell settings');
      }
    }
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");


    const excelBuffer2: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // this.saveExcelFile(excelBuffer2, "test");
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    var wscols = [
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 16 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 40 },
      { wch: 40 },
      { wch: 30 },
      { wch: 30 },
    ];

    ws['!cols'] = wscols;
    for (var i in ws) {
      if (typeof ws[i] != 'object') continue;
      let cell = XLSX.utils.decode_cell(i);

      ws[i].s = {
        // styling for all cells
        font: {
          name: 'arial', sz: 10
        },
        alignment: {
          vertical: 'center',
          horizontal: 'center',
          wrapText: '1', // any truthy value here
        },
        border: {
          right: {
            style: 'thin',
            color: '000000',
          },
          left: {
            style: 'thin',
            color: '000000',
          },
        },
      };



      if (cell.r == 0) {
        // first row
        ws[i].s.border.bottom = {
          // bottom border
          style: 'thin',
          color: '000000',
        };
        ws[i].s.fill = {
          // background color
          patternType: 'solid',
          fgColor: { rgb: '99cc00' },
          bgColor: { rgb: '99cc00' },
        };
      }


    }
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    FileSaver.saveAs(data, fileName + this.fileExtension);
  }

  getReSizedImages(fileName: any) {

    return this.genereicService.Get<any>(`Product/ResizeImages?filename=${fileName}`);
  }

  public onGetTableHardersAsHtml(data: any): string {
    var headerTemp = `
    <style>
    body {
      font-family: Arial, sans-serif;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
      padding-top: 50px;
    }

    th, td {
      padding: 10px;
      text-align: left;
      border: 1px solid #000;
    }

    th {
      background: #C5D646;
      font-weight: bold;
    }
    tbody tr:nth-child(even) {
      background-color: #f9f9f9;
    }

  </style>
    <thead>
      <tr style="border: 2px solid #000; background: #C5D646;"> 
       
        <th scope="col" style="width: 10%" sortable="title">Product Image</th>
        <th scope="col" style="width: 10%" sortable="title">Ref</th>
        <th scope="col" style="width: 10%" sortable="title">LinkProduct</th>
        <th scope="col" style="width: 10%" sortable="title">Barcode</th>
        <th scope="col" style="width: 10%" sortable="title">Descr</th>
        <th scope="col" style="width: 10%" sortable="title">CNTS Order</th>
        <th scope="col" style="width: 10%" sortable="title">PCS/CNT</th>
        <th scope="col" style="width: 10%" sortable="title">PCS</th>
        <th scope="col" style="width: 10%" sortable="title">RMB</th>
        <th scope="col" style="width: 10%" sortable="title">CARDS</th>
        <th scope="col" style="width: 10%" sortable="title">USD PCS</th>
        <th scope="col" style="width: 10%" sortable="title">USD TOTAL</th>
        <th scope="col" style="width: 20%" sortable="title">CBM</th>
        <th scope="col" style="width: 10%" sortable="title">TOTAL CBM</th>
        <th scope="col" style="width: 10%" sortable="title">Packing</th>
        <th scope="col" style="width: 10%" sortable="title">PESO EMBALAGEM</th>
        <th scope="col" style="width: 10%" sortable="title">Notas</th>
      </tr>
    </thead>`;
    // <th scope="col" style="width: 10%" sortable="title">Order No.</th>
    return headerTemp;
  }


  convertedImage: any;

  async onGetTablebodyAsHtml(data: any) {
    var bodyTemp = `<tbody style="border: 1px solid #000; font-size: 14px; font-size: 14px; font-family: Arial, sans-serif">`;

    for (let i = 0; i < data.length; i++) {
      let record = data[i];
      if (record.ProdfilesPath?.length > 0) {
        var ProdfilesPath = record.ProdfilesPath[0];

        let lres = await this.getReSizedImages(ProdfilesPath)?.toPromise();
        bodyTemp +=
          `<style type="text/css">
          .table-img{
            height: 50px; 
            width: 50px; 
            overflow: hidden;
            background:red;
          }
        </style>
        <tr>
       
        <td style="border: 1px solid #000; padding: 4px;">
          <div>
            <img src="${lres.Data}" alt="No Image found" class="table-img" />
          </div>
        </td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Codigo_Produto}</td>
          <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${ ''}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Codigo_Barras ? record.Codigo_Barras : '--'}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.C_Produto}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">CNTS Order</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">PCS/CNT</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Q_Produto}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">RMB</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.N_ValorApresentacao}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.values.toFixed(2)}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.TotalValues.toFixed(2)}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.N_AreaCaixa}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.TotalCBM}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.pesoPacking ? record.pesoPacking : '--'}</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">PESO EMBALAGEM</td>
        <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.nota ? record.nota : '--'}</td>
        </tr>`;
      }
      // <td style="border: 1px solid #000; padding: 4px; font-weight: bold;">Order No.</td>
    }

    return bodyTemp + `</tbody>`;
  }

  async getFinalTotalBox(data: any) {
    var totalBox = `
    <style>
      .total {
        width: 50px;
        margin-top: 50px;
        margin-right: 180px;
      }
      
    </style>
    <div class="total">
    <table>
    <thead>
      <tr>
        <th style="border: 1px solid #000;  font-weight: bold;">Total Volumn</th>
        <th style="border: 1px solid #000;  font-weight: bold;">Total Pcs</th>
        <th style="border: 1px solid #000;  font-weight: bold;">Total Value</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="border: 1px solid #000;  font-weight: bold;">${data.TotalVolume}</td>
        <td style="border: 1px solid #000;  font-weight: bold;">${data.TotalPcs}</td>
        <td style="border: 1px solid #000;  font-weight: bold;">${data.TotalFinalValues.toFixed(3)}</td>
      </tr>
    </tbody>
    </table>
    </div> `;
    return totalBox;
  }


  // async onGetTablebodyAsHtml_Test(data: any) {
  //   var bodyTemp = `<tbody style="border: 1px solid #000; font-size: 14px; font-size: 14px; font-family: Arial, sans-serif">`;
  //   const doc = new jsPDf();

  //   var headerTemp = `
  //   <style>
  //   body {
  //     font-family: Arial, sans-serif;
  //   }

  //   table {
  //     width: 100%;
  //     border-collapse: collapse;
  //     margin-bottom: 20px;
  //   }

  //   th, td {
  //     padding: 10px;
  //     text-align: left;
  //     border: 1px solid #000;
  //   }

  //   th {
  //     background: #C5D646;
  //     font-weight: bold;
  //   }
  //   tbody tr:nth-child(even) {
  //     background-color: #f9f9f9;
  //   }

  //   // img {
  //   //   height: 50px;
  //   //   aspect-ratio: auto 50 / 50;
  //   //   width: 50px;
  //   // }
  // </style>
  //   <thead>
  //     <tr style="border: 2px solid #000;">
  //       <th scope="col" style="width: 10%" sortable="title">Product Image</th>
  //       <th scope="col" style="width: 10%" sortable="title"> Code</th>
  //       <th scope="col" style="width: 20%" sortable="title"> Product Dascription</th>
  //       <th scope="col" style="width: 10%" sortable="title"> No. boxes</th>
  //       <th scope="col" style="width: 10%" sortable="title"> Products per Box</th>
  //       <th scope="col" style="width: 10%" sortable="title"> No. products</th>
  //       <th scope="col" style="width: 30%" sortable="title"> Value</th>
  //       <th scope="col" style="width: 30%" sortable="title"> Total Value</th>
  //       <th scope="col" style="width: 10%" sortable="title"> CBM (m3)</th>
  //       <th scope="col" style="width: 10%" sortable="title"> Total CBM</th>
  //     </tr>
  //   </thead>`;

  //   for (let i = 0; i < data.length; i++) {
  //     let record = data[i];
  //     var ImagePath = record.ImagePath[0];
  //     console.log(record);
  //     let lres = await this.getReSizedImages(ImagePath).toPromise();
  //     const img = new Image();
  //     img.src = lres.Data;
  //     console.log(img.src);

  //     bodyTemp +=
  //       ` <style type="text/css">
  //         .table-img{
  //           height: 50px; 
  //           width: 50px; 
  //           overflow: hidden;
  //           background:red;
  //         }
  //       </style>
  //       <tr>
  //       <td style="border: 1px solid #000; padding: 5px;">
  //         <div>
  //           <img src="${img.src}" alt="No Image found" class="table-img" />
  //         </div>
  //       </td>
  //       <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Codigo_Produto}</td>
  //       <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.C_Produto}</td>
  //       <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.NoOfBoxes}</td>
  //       <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.N_ItensPorCaixa}</td>
  //       <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.Q_Produto}</td>
  //       <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.values - .2 - 2}</td>
  //       <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.TotalValues - .2 - 2}</td>
  //       <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.N_AreaCaixa}</td>
  //       <td style="border: 1px solid #000; padding: 5px; font-weight: bold;">${record.TotalCBM}</td>
  //       </tr>`;
  //     doc.addImage(img.src, 'jpeg', 10, 20, 10, 10);
  //   }
  //   bodyTemp = bodyTemp + `</tbody>`;
  //   const htmlContent = headerTemp + bodyTemp;
  //   const table = document.createElement('div');
  //     table.innerHTML = htmlContent;
  //     const htmlTable = table.querySelector('table');
  //     console.log(htmlTable);
  //   autoTable(doc,{html: htmlTable })
  //   doc.save('Service_File.pdf');
  //   return bodyTemp + `</tbody>`;
  // }




}
