import { Component, HostListener, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifyService } from 'src/app/shared/service/notify.service';
import { TableService } from 'src/app/shared/service/table.service';
import { ProductService } from 'src/app/shared/service/product.service';
import { ProductManagementService } from 'src/app/shared/service/product-management.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-suggested-products',
  templateUrl: './suggested-products.component.html',
  styleUrls: ['./suggested-products.component.scss']
})
export class SuggestedProductsComponent implements OnInit {

  Clients: any[] = [];
  selectedClient: any;
  suggestedProdutList: any[] = [];
  clients_ID: any;
  productIDs: any[] = [];

  pageConfig: any = {
    filters: {
      iD_Entidade: '',
    },
    pageSize: 10,
    page: 1,
  }

  debounceTimeout: any;
  windowBottom: number = 0;
  docHeight: number = 0;
  isLoading = false;

  constructor(
    private productService: ProductService,
    private spinner: NgxSpinnerService,
    private notify: NotifyService,
    private tableServices: TableService,
    private productManageService: ProductManagementService,
    private Router: Router
  ) { }


  ngOnInit(): void {
    this.getAllClients();
    this.productService.SuggestedProductsListTop10.subscribe((data) => {
      this.suggestedProdutList = data;
    });
  }

  
  getAllSuggestedProductList(event: any) {
    this.spinner.show();
    this.productService.getAllSuggestedProductList(event.iD_Entidade).subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.spinner.hide();
        this.suggestedProdutList = res.Data;
      }
      else {
        this.spinner.hide();
        this.suggestedProdutList = [];
      }
    }, (error) => {
      this.spinner.hide();
      this.suggestedProdutList = [];
    });
  }


  GoToDetail(id) {
    this.Router.navigate(['products/digital/digital-detail', id]);
  }

  getAllClients() {
    this.tableServices.getAllClients().subscribe((res: any) => {
      if (res.ResponseCode == 200) {
        this.Clients = res.Data;
        this.Clients.forEach((e) => {
          this.selectedClient = e.ID_Entidade
        })
      } else {
        this.Clients = [];
      }
    }, (error) => {
      this.Clients = [];
    })
  }

  DeleteFromSuggestions(idProduct : any) {
  
   // var idProduct: any
    var data = {
      idEntidate: [
        this.pageConfig.filters.iD_Entidade
      ],
      idProduct: [
        idProduct
      ]
    }
    if (data.idEntidate.length !== null && data.idProduct.length > 0) {
      this.notify.showConfim('Remove Product Form Suggestion', 'Are you sure want to remove product from suggestions ?', () => {
        this.productManageService.DeleteFromSuggestion(data).subscribe((res: any) => {
          if (res.ResponseCode == 200) {
            this.spinner.hide();
            this.notify.showSuccess('Success', 'Product removed from suggestion');
            this.suggestedProdutList = this.suggestedProdutList.filter(obj => obj.ID_Produto !== idProduct);
            this.productService.getAllSuggestedProductList(data.idEntidate);
            // window.location.reload();
            // this.productService.onCallSuggestedPagination(data.idEntidate);
          }
          else if (res.ResponseCode == 404) {
            this.spinner.hide();
            this.notify.showWarning('Warning', 'Product not Found');
          }
          else {
            this.spinner.hide();
            this.notify.showError('Error', 'Something Went Wrong');
          }
        }, (error) => {
          this.spinner.hide();
        });
      })

    }
  }


  filterBy(pagesize?: any) {
    if (pagesize)
      this.productService.page = 1;
    this.productService.sortColumn = 'ID_Produto';
    this.productService.sortDirection = 'desc';
    this.productService._suggestState.searchTerm.ID_Entidade = this.pageConfig.filters.iD_Entidade
    this.productService.onCallSuggestedPagination();
  }

  DeleteAllFromSuggestions() {
    const idEntidade = [this.pageConfig.filters.iD_Entidade];
    const idProduct = this.suggestedProdutList.map(e => e.ID_Produto);
    const data = {
      idEntidate: idEntidade,
      idProduct: idProduct
    };

    if (data.idEntidate.length !== null && data.idProduct.length > 0) {
      this.notify.showConfim('Delete Product', 'Are you sure want to delete product from suggestions ?', () => {
        this.productManageService.DeleteFromSuggestion(data).subscribe((res: any) => {
          if (res.ResponseCode == 200) {
            this.spinner.hide();
            this.notify.showSuccess('Success', 'Product removed from suggestion');
            this.productService.onCallSuggestedPagination();
          }
          else if (res.ResponseCode == 404) {
            this.spinner.hide();
            this.notify.showWarning('Warning', 'Product not Found');
          }
          else {
            this.spinner.hide();
            this.notify.showError('Error', 'Something Went Wrong');
          }
        });
      })
    }

  }

}
