import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-to-approve',
  templateUrl: './to-approve.component.html',
  styleUrls: ['./to-approve.component.scss']
})
export class ToApproveComponent implements OnInit {

  products = [
    { id: 1, name: 'Product A', isApproved: false },
    { id: 2, name: 'Product B', isApproved: false },
    { id: 3, name: 'Product C', isApproved: false }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  onApprove(product: any): void {
    product.isApproved = !product.isApproved;
    console.log(`Product ${product.name} is approved: ${product.isApproved}`);
  }

}
